<template>
    <div class="w-full">
    <Slider
      v-model="sliderData.value"
      :format="format"
      :min="sliderData.min"
      :max="sliderData.max"
      @change="onChange"
    ></Slider>
    </div>
</template>
<style src="@vueform/slider/themes/default.css"></style>
<script>
//https://vuejsexamples.com/vue-3-slider-component-with-multihandles-and-formatting/
  import Slider from '@vueform/slider/dist/slider.vue2.js'
  import EnergyEfficiency from '@/data/Energy'

  export default {
    components: { 
        Slider 
    },
    props:{
        blockType: String,
        min:{
          Number,
          default: 0
        },
        max:{
          Number,
          default: 7
        }

    },
    data: () => ({
        allEnergy: EnergyEfficiency,
        sliderData: {
            value: [0, 7],
            min: 0,
            max: 7
        },
    }),
    created(){
      this.sliderData.value = [this.min, this.max]
    },
    methods:{
      format: function (index) {
          return this.allEnergy[index]
        },
        onChange(e){
          this.$emit('on-change', this.blockType, e)
        }
    }
  }
</script>