<template>
    <div :class="completeClass">
        <inline-svg fill="black" class="w-3 h-3 absolute top-0 right-0 m-1 lg:m-3 transform rotate-90" src="/svg/arrow/arrow.svg" />
        
        <select 
            @change="onChange($event)" 
            :class="placeHolderEffect.active"
        >
            <option disabled hidden selected>{{myPlaceholder}}</option>

            <option :class="placeHolderEffect.disabled"
                v-for="(item, key) in items" 
                :key="key"
            >
                {{ $processLanguage(item.name) }}
            </option>
        </select>
    </div>
</template>

<script>

export default {
    name: "Dropwdown",
        props: {
            blockType: String,
            items: {
                type: Array,
                default: null
            },
            myPlaceholder: String,
            roundAllCorners:{
                type: Boolean,
                default: false
            }
        },
        data(){
            return{
                hideOptions: true,
                placeHolderEffect: {
                    active: 'text-black text-base text-opacity-50 font-euclid px-4 block h-full bg-transparent w-full appearance-none cursor-pointer',
                    disabled: 'text-black text-base font-euclid px-4 block h-full bg-transparent w-full appearance-none cursor-pointer'
                }
            }
        },
        computed:{
            completeClass(){
                let finalClass = 'flex bg-white flex border border-gray w-full lg:w-4/5 relative items-center h-5 lg:h-10 relative '
                if(this.roundAllCorners)
                    finalClass += 'rounded-lg'
                    
                
                return finalClass
            }
        },
        methods: {
            onChange(e){
                this.$emit('on-change', this.blockType, e.target.value)
            },
            showOptions(){
                this.hideOptions = !this.hideOptions
            }
        }
}
</script>