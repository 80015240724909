<template>
    <div class="flex-shrink bg-white rounded-lg border border-gray lg:border-0 w-11/12 lg:w-full h-5 lg:h-8">
       <switchButton name="map" v-model="checkbox" :label="$t('listingsPage.bar.map')" :defaultChecked="getCheckbox"></switchButton>
    </div>
</template>

<script>
import switchButton from "@/components/listings/filters/inputs/SwitchButton.vue";
import {
    mutations,
} from '@/core/store'

export default {
    name: "MapSwitch",
    components: {
        switchButton
    },
    data() {
        return {
            checkbox: false
        }
    },
    methods:{
        changeSwitch(v){
            this.checkbox = v
        }
    },
    watch: {
        //this code makes the store value change when the page is mounted again (it can't do that). bind value to vuex
        checkbox: function () {
            mutations.viewMapToogle(this.checkbox);
        },
    },
    computed:{
        getCheckbox(){
            return this.checkbox
        }
    }
}
</script>

