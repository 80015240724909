<template>
    <div class="absolute bg-white rounded-lg border border-bronze z-20 w-1/5 h-40 listing-filter-modal">
        <div class="ml-4 mt-6 div-content text-baselg font-euclid font-bold uppercase tracking-wide">
            {{ $t('houseTypeDiv.title') }}
            <button class="close" @click="hideMenu">
              <inline-svg class="transform origin-center rotate-45 w-1 h-1 md:w-2 md:h-2 lg:w-5 lg:h-5" fill="black"
                src="/svg/plus.svg" />
            </button>
        </div>
        <div class="mt-4 ml-4 mr-4 z-40 bg-white" v-show="isOpen">         
            <FilterDropdown
                :key="reloadFilterDropdown"
                :items="items"
                :roundAllCorners="true"
                :blockType="blockType"
                @on-change="onChange"
                :myPlaceholder="computePlaceholder"
            />
        </div>
        <div class="mt-4 ml-4 text-gray"><a @click="cleanFilter" class="cursor-pointer text-sm">{{ $t('houseTypeDiv.clean') }}</a></div>
    </div>
</template>
<style scoped>
.listing-filter-modal{
  padding: 0 1rem;
  height: 10.5rem;
}
.close{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
<script>
import FilterDropdown from "@/components/listings/filters/inputs/dropdowns/FilterDropdown.vue";

export default {
    // Variável que contém a lingua que precisamos
    // process.env.VUE_APP_I18N_LOCALE
    components: {
      FilterDropdown
    },
    data: () => ({
        isOpen: true,
        value: [0, 10],
        placeholderData: '',
        reloadFilterDropdown: 'A' + 'B',
        outsideClickQty: 0
    }),
    name: "HouseTypeDropdownMenu",
        props: {
            filters: {
                type: Array,
                default: null
            },
            blockType: String,
        },
        created(){
            this.placeholderData = this.$t('listingsPage.advancedFilters.dropdownPlaceholders.houseType')
            window.addEventListener('click', (e) => {
                if (!this.$el.contains(e.target)){
                    if(this.outsideClickQty >= 1){
                        this.outsideClickQty = 0
                        this.$emit('close-me')
                    }
                }
                this.outsideClickQty++
            })
        },
        destroyed(){
            window.removeEventListener('click', (e) => {
                if (!this.$el.contains(e.target)){
                    if(this.outsideClickQty >= 1){
                        this.outsideClickQty = 0
                        this.$emit('close-me')
                    }
                }
                this.outsideClickQty++
            })
        },
        computed: {
          items(){
              return this.filters
          },
          computePlaceholder(){
              return this.placeholderData
          }
        },
        methods: {
            hideMenu(){
              this.$emit('close-me')
            },
            onChange(bt, data){
                this.placeholderData = data
                this.$emit('on-change', bt, data)
            },
            cleanFilter(){
                this.placeholderData = this.$t('listingsPage.advancedFilters.dropdownPlaceholders.houseType')
                this.reloadFilterDropdown++
                this.$emit('clean-price-filter')
            }
        }
}
</script>