export class Property {
  constructor(object = null) {
    this.id = object.id || null,
    this.tag_id = object.tag_id || null,
    this.tag = object.tag
    this.price = object.price || 0,
    this.condominium_id = object.condominium_id || null
    this.reference = object.reference || ''
    this.archived = object.archived || 0
    this.information = {
      title: object.title || '-',
      description: object.description || '-',
      location: {
        district: object.district.name || '',
        type: object.type.name || ''
      }
    }
    this.price = object.price
    this.condominium_id = object.condominium_id
    this.parking = object.parking || 0
    this.slogan = object.slogan || ''
    this.kpis = {
      beds: object.bedrooms || 0,
      baths: object.bathrooms || 0,
      m2: object.gross_area || 0
    }
    this.media = {
      id: object.id || null,
      cover: object.media.cover || 'https://www.keystoneproperties.net/wp-content/themes/keystoneproperties/img/property-placeholder.png'
    }
    this.agent = {
      name: {
        first: object.agent.first_name || '',
        last: object.agent.last_name || ''
      },
      avatar: object.agent.avatar || 'http://placehold.jp/20/ffffff/202225/900x250.png?text='
    }
    this.type_name = object.type.name || ''
    this.floor = object.floor || 0
    this.features_id = object.features || ''
    this.elevator = object.elevator == "Yes" ? true : false
    this.collection = object.collection || 0
    this.energy = object.energy || ''
    this.hide_price = object.hide_price
    this.administrative_area_level_2 = object.administrative_area_level_2 || null
    this.administrative_area_level_3 = object.administrative_area_level_3 || null
    this.political = object.political || null
    this.gmap_county = object.gmap_county || null
    this.gmap_district = object.gmap_district || null
    this.radius_size = object.radius_size || null
    this.lat = object.lat || null
    this.len = object.len || null
    this.for_sale = object.for_sale
  }
}

export class PropertyForm {
  constructor(
    {
      id = String(),
      tag_id = String(),
      reference = String(),
      i18n = {
        title: Array(),
        description: Array(),
        slogan: Array()
      },
      price = Number(),
      bedrooms = Number(),
      bathrooms = Number(),
      parking = Number(),
      exterior_area = String(),
      gross_area = String(),
      living_area = Number(),
      construction = String(),
      construction_area_type = String(),
      lote_size = Number(),
      lote_size_type = String(),
      land_size = Number(),
      land_size_type = String(),
      agent_id = Number(),
      district_id = Number(),
      types = Array(),
      archived = Number(),
      search = Array(),
      condominium_list = Array(),
      hide_price = Number(),
      collection = Number(),
      condominium_id = Number(),
    }) {
    this.id = id || null
    this.tag_id = tag_id || null
    this.reference = reference || null
    this.i18n = {
      title: { en: i18n.title.en || '', pt: i18n.title.pt || '' },
      description: { en: i18n.description.en || '', pt: i18n.description.pt || '' },
      slogan: { en: i18n.slogan.en || '', pt: i18n.slogan.en || '' }
    }
    this.price = price || null
    this.bedrooms = bedrooms
    this.bathrooms = bathrooms
    this.parking = parking
    this.gross_area = gross_area
    this.exterior_area = exterior_area
    this.construction = construction
    this.construction_area_type = construction_area_type
    this.lote_size = lote_size
    this.lote_size_type = lote_size_type
    this.land_size = land_size
    this.land_size_type = land_size_type
    this.living_area = living_area
    this.agent_id = agent_id || null
    this.district_id = district_id || null
    this.types = types || null
    this.archived = archived || null
    this.search = search || null
    this.condominium_list = condominium_list || null
    this.hide_price = hide_price || 0
    this.collection = collection || 0
    this.condominium_id = condominium_id || null
  }
}

export class PropertySEOForm {
  constructor(
    {
      id = String(),
      title = String(),
      slug = String(),
      description = String()
    }) {
    this._id = id || null
    this.title = title
    this.slug = slug
    this.description = description
  }
}

export class PropertieFromCondominium{
  constructor(
    object = null
  ){
    this.id = object.id
    this.reference = object.reference
    this.type_name = object.type.name
    this.bedrooms = object.bedrooms
    this.bathrooms = object.bathrooms
    this.gross_area = object.gross_area
    this.exterior_area = object.exterior_area
    this.parking = object.parking
    this.floor = object.floor
    this.price = object.price
    this.tag = object.tag
    this.archived = object.archived
    this.attachedFile = object.attachment
    this.fraction = object.fraction
    this.type = {
      en: object.type.en,
      pt: object.type.pt
    }
  }
}


export class PropertyCondominiumList {
  constructor(object = null) {
    this.id = object.id
    this.title = object.title
    this.price = object.price
    this.condo_where = object.condo_where
    this.description = object.description
  }
}

export class PropertyLocation {
  constructor(object = null){
    this.address = object.address
    this.lat = object.lat
    this.len = object.len
    this.drawer = object.drawer
  }
}


export class PropertyHomePage {
  constructor(object = null) {
    this.id = object.id || null,
    this.title = {
      en: object.title.en,
      pt: object.title.pt
    }
    this.description = {
      en: object.description.en,
      pt: object.description.pt
    }
    this.slogan = {
      en: object.slogan.en,
      pt: object.slogan.pt
    }
    this.location = {
      en: object.district.name.en,
      pt: object.district.name.pt
    }
    this.price = object.price
    
    this.media = {
      id: object.id || null,
      cover: object.media.cover || 'https://www.keystoneproperties.net/wp-content/themes/keystoneproperties/img/property-placeholder.png'
    }
    
    this.hide_price = object.hide_price
    this.political = object.political || null

    // Equal property class to avoid troubles
    this.tag_id = object.tag_id || null,
    this.tag = {
      en: object.tag.en,
      pt: object.tag.pt
    }
    this.price = object.price || 0,
    this.condominium_id = object.condominium_id || null
    this.reference = object.reference || ''
    this.archived = object.archived || 0
    this.information = {
      title: object.title || '-',
      description: object.description || '-',
      location: {
        district: object.district.name || '',
        type: object.type.name || ''
      }
    }
    this.price = object.price
    this.condominium_id = object.condominium_id
    this.parking = object.parking || 0
    this.kpis = {
      beds: object.bedrooms || 0,
      baths: object.bathrooms || 0,
      m2: object.gross_area || 0
    }
    this.agent = {
      name: {
        first: object.agent.first_name || '',
        last: object.agent.last_name || ''
      },
      avatar: object.agent.avatar || 'http://placehold.jp/20/ffffff/202225/900x250.png?text='
    }
    this.type_name = object.type.name || ''
    this.floor = object.floor || 0
    this.features_id = object.features || ''
    this.elevator = object.elevator == "Yes" ? true : false
    this.collection = object.collection || 0
    this.energy = object.energy || ''
    this.hide_price = object.hide_price
    this.administrative_area_level_2 = object.administrative_area_level_2 || ''
    this.administrative_area_level_3 = object.administrative_area_level_3 || ''
    this.gmap_county = object.gmap_county || ''
    this.gmap_district = object.gmap_district || ''
  }
}



export default { PropertyLocation, Property, PropertyForm, PropertySEOForm, PropertieFromCondominium, PropertyCondominiumList, PropertyHomePage }
