<template>
    <div>
        <!-- Features -->
        <input-checkbox-multiple
                :items="items"
                custom-class="mt-0 no-messages"
                :status="0"
                v-model="formData"
                :fill="form"
                @input="onChecked"
                :checkedItems="checkedFeatures"
        ></input-checkbox-multiple>
        <!-- end -->
    </div>
</template>

<script>
  import { buildPayloadPagination } from '@/utils/utils'
  import mixin from '@/models/keys/KeyModel'

  export default {
    name: 'Features',

    mixins: [mixin],

    data: () => ({
      dataLoading: true,
      pagination: {},
      target: 'features',
      formData: []
    }),

    props: {
      form: {},
      blockType: String,
      checkedFeatures: null
    },

    methods: {
      onChecked(e){
        this.$emit('on-change', this.blockType, e)
      },
      buildSearch() {
        return this.search
          ? { query: this.search, fields: this.fieldsToSearch.join(',') }
          : {}
      }
    },

    watch: {
      form: {
        handler: function(val) {
          this.formData = val
        },
        deep: true,
        immediate: true
      },
      formData: {
        handler: function(val) {
          this.$emit('get-features', val)
        },
        deep: true
      },
      pagination: {
        async handler() {
          try {
            this.dataLoading = true
            await this.getKeyItems(
              buildPayloadPagination({}, this.buildSearch(), this.target)
            )
            this.dataLoading = false
          } catch (error) {
            this.dataLoading = false
          }
        },
        deep: true,
        immediate: true
      }
    },

    computed: {
      items() {
        let orderedItems = this.$store.state.keysModule.key.features.data
        
        // Alphabetic order [Numbers], A, B, C ... Z
        let parent = this
        orderedItems = orderedItems.sort(function(a, b) {
          var nameA = parent.$processLanguage(a.name).toUpperCase(); // ignore upper and lowercase
          var nameB = parent.$processLanguage(b.name).toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1; //nameA comes first
          }
          if (nameA > nameB) {
            return 1; // nameB comes first
          }
          return 0;  // names must be equal
        })
        return orderedItems
      }
    }
  }
</script>