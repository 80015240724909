import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = Vue.observable({
    mapView: false
});
  
  export const mutations = {
    viewMapToogle(value){
        store.mapView = value;
    }
  };