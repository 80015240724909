<template>
    <div class="bg-white rounded-lg px-2 flex items-center justify-center leading-none h-8" @click="removeFilters()">
        <p class="truncate"> {{ $t('listingsPage.bar.removeFilters') }}</p>
    </div>
</template>
<script>
export default {
    name: "RemoveFilters",
    props: {filtersNumber: Number},
    methods:{
        removeFilters(){
            this.$emit('remove-filters')
        }
    }
}
</script>