<template>
    <div class="absolute bg-white rounded-lg border border-bronze z-20 w-1/5 h-40 listing-filter-modal">
        <div class="ml-4 mt-6 div-content text-baselg font-euclid font-bold uppercase tracking-wide">
            {{ $t('bedsDiv.title') }}
            <button class="close" @click="hideMenu">
              <inline-svg class="transform origin-center rotate-45 w-1 h-1 md:w-2 md:h-2 lg:w-5 lg:h-5" fill="black"
                src="/svg/plus.svg" />
            </button>
        </div>
        <div class="mt-4 ml-4 mr-4 z-40 bg-white" v-if="isOpen">          
            <ButtonsList
                @on-change="onChange" class="rounded-l-lg cursor-pointer " :blockType="blockType" :items="items" :selectedButton="selectedButton"/>
        </div>
        <div class="mt-4 ml-4 text-gray"><a @click="cleanFilter" class="cursor-pointer text-sm">{{ $t('bedsDiv.clean') }}</a></div>
    </div>
</template>
<style scoped>
.listing-filter-modal{
  padding: 0 1rem;
  height: 10.5rem;
}
.close{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
<script>
import ButtonsList from "@/components/listings/filters/inputs/ButtonsList.vue";

export default {
    // Variável que contém a lingua que precisamos
    // process.env.VUE_APP_I18N_LOCALE
    components: {
      ButtonsList
    },
    data: () => ({
        isOpen: true,
        value: [0, 10],
        outsideClickQty: 0,
        selectedButton: null
    }),
    name: "Slider",
        props: {
            filters: {
                type: Array,
                default: null
            },
            blockType: String,
            filtersInUse: {
                type: Array,
                default: null
            }
        },
        created(){
            // Guardar se algo já foi usado nos filtros avançados
            if(this.filtersInUse.find(p => p.id === "beds").data != "All")
                this.selectedButton = this.filtersInUse.find(p => p.id === "beds").data
            else
                this.selectedButton = "-1"


            if(this.selectedButton == "5+")
                this.selectedButton = "5"

            // Registo do evento para a janela fechar em click de fora
            window.addEventListener('click', (e) => {
                if (!this.$el.contains(e.target)){
                    if(this.outsideClickQty >= 1){
                        this.outsideClickQty = 0
                        this.$emit('close-me')
                    }
                }
                this.outsideClickQty++
            })
        },
        destroyed(){
            window.removeEventListener('click', (e) => {
                if (!this.$el.contains(e.target)){
                    if(this.outsideClickQty >= 1){
                        this.outsideClickQty = 0
                        this.$emit('close-me')
                    }
                }
                this.outsideClickQty++
            })
        },
        computed: {
          items(){
              return this.filters
          }
        },
        methods: {
            hideMenu(){
              this.$emit('close-me')
            },
            onChange(bt, data){
                this.outsideClickQty = 0
                this.$emit('on-change', bt, data)
            },
            cleanFilter(){
                this.$emit('clean-price-filter')
            }
        }
}
</script>