import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug(`Condominiums action called.`)
  },

  methods: {
    ...mapActions(['getHomepageCondominiums', 'getCondominiums', 'getCondominiumBlock', 'getCondominiumMediaBlock', 'getCondominiumAttachmentBlock']),
    


  }
  

  
}

export default mixin