<template>
    <div class="absolute bg-white rounded-lg border border-bronze z-20 w-1/5 h-40 listing-filter-modal">
        <div class="ml-4 mt-6 text-baselg font-euclid font-bold uppercase tracking-wide">
          {{ $t('pricingDiv.title') }}
          <button class="close" @click="hideMenu">
            <inline-svg class="transform origin-center rotate-45 w-1 h-1 md:w-2 md:h-2 lg:w-5 lg:h-5" fill="black"
              src="/svg/plus.svg" />
          </button>
        </div>
        <div class="mt-4 ml-4 mr-4 z-40 bg-white" v-if="isOpen">             
          <MinMaxInput 
                @on-change="onChange" 
                blockType="pricing"
                :min="filters.find(p => p.id === 'pricing').data.min"
                :max="filters.find(p => p.id === 'pricing').data.max"
            />
        </div>
        <div class="mt-4 ml-4 text-gray"><a @click="cleanFilter" class="cursor-pointer text-sm">{{ $t('pricingDiv.clean') }}</a></div>
    </div>
</template>
<style scoped>
.listing-filter-modal{
  padding: 0 1rem;
  height: 10.5rem;
}
.close{
  position: absolute;
  top: 1rem;
  right: 1rem;
}
</style>
<script>
import MinMaxInput from "@/components/listings/filters/inputs/MinMaxInput.vue";

export default {
    components: {
      MinMaxInput
    },
    data: () => ({
        isOpen: true,
        value: [0, 10],
        outsideClickQty: 0
    }),
    name: "Slider",
    props: {
        filters: {
            type: Array,
            default: null
        },
        blockType: String,
    },
    created(){
        window.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)){
                if(this.outsideClickQty >= 1){
                    this.outsideClickQty = 0
                    this.$emit('close-me')
                }
            }
            this.outsideClickQty++
        })
    },
    destroyed(){
        window.removeEventListener('click', (e) => {
            if (!this.$el.contains(e.target)){
                if(this.outsideClickQty >= 1){
                    this.outsideClickQty = 0
                    this.$emit('close-me')
                }
            }
            this.outsideClickQty++
        })
    },
    methods: {
        hideMenu(){
          this.$emit('close-me')
        },
        onChange(bt, data){
            this.$emit('on-change', bt, data)
        },
        cleanFilter(){
            this.$emit('clean-price-filter')
        }
    }
}
</script>
