<template>
    <div class="w-full h-6 lg:h-10 rounded-lg cursor-pointer mt-4" :key="reloadDiv" v-if="dataLoaded">
        <div v-for="(item, i) in getItems" :key="i" 
            :class="computeOutsideClass(i)"
        >
            
            <div class="cursor-pointer"
                @click="clickedDiv(i, $processLanguage(item.name))"
                :class="computeInsideClass(i)">
                <label class="text-md lg:text-base font-euclid cursor-pointer mt-1 lg:mt-2">{{ $processLanguage(item.name) }}</label>
            </div>

           
        </div>
    </div>
</template>
<script>

export default({
   name: "ButtonsList",
   props:{
       items: Array,
       blockType: String,
       selectedButton: String
   },
   data(){
       return{
           dataLoaded: false,
           selectedItem: 0,
           reloadDiv: 0,
           outsideClass: 'inline-block w-1/6 h-full relative align-middle text-center',
           insideDivClass: 'flex justify-center flex items-stretch inset-0 align-middle text-center inline-block'
       }
   },
   created(){
       //(this.items)  
       this.selectedItem = this.selectedButton
       this.dataLoaded = true
   },
   methods:{
      clickedDiv(i, v){
          // Se clicarmos duas vezes no mesmo, então a seleção desaparece e volta a mostrar TODOS (ALL)
          if(i==this.selectedItem){
            this.selectedItem = -1
            this.reloadDiv += 1
            this.$emit('on-change', this.blockType, "All")
            return
          }
        // Caso contrário, filtramos pelo valor selecionado
          this.selectedItem = i
          this.reloadDiv += 1
          this.$emit('on-change', this.blockType, v)
      },
      computeOutsideClass(i){
        let classString = ''
        if(i==this.selectedItem)
            classString += 'bg-bronze border-bronze'
        else
            classString += 'border-black border-opacity-25'

        if(i==0)
            classString += ' rounded-tl-lg rounded-bl-lg'

        if(i==this.items.length-1)
            classString += ' rounded-tr-lg rounded-br-lg'

        // Inside borders to avoid borders above each other
        let addBorder = ''
        
        // If first or last, border all
        if(i==0 || i==this.items.length-1)
            addBorder = ' border'

        // If it is the second or the antepenultimate
        if(i >= 1 && i < this.items.length-2)
            addBorder = ' border-r border-t border-b'

        // If it is the penultimate
        if(i==this.items.length-2)
            addBorder = ' border-b border-t'
        
        
        return this.outsideClass + ' ' + classString + addBorder
      },
      computeInsideClass(i){
        let classString = ''
        if(i==this.selectedItem)
            classString += 'text-white'
        else
            classString += 'text-black text-opacity-50'

        
        return this.insideDivClass + ' ' + classString
      }
   },
   computed:{
       getItems(){
           return this.items
       }
   }
})
</script>
