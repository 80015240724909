<template>
    <!-- TO DO: fix height on mobile -->
    <div class="mt-0 lg:rounded-l-3xl md:rounded-l-2xl fixed bg-white border-t-2 md:border-t-0 md:border-l-2 border-lightgray h-screen z-40 w-screen md:w-3/4 lg:w-2/6 right-0 flex flex-col">
        <!-- title and close button -->
        <div class="lg:h-16 md:h-10 h-6 border-b border-lightgray border-opacity-10 flex-none px-2 lg:px-8 flex justify-between items-center">
            <p class="text-lg font-blackMango font-bold uppercase tracking-wide">
                {{ $t('listingsPage.advancedFilters.title') }}</p>
            <button 
                @click="openFilters">
                <inline-svg class="transform rotate-45 w-3 h-3 md:w-5 md:h-5 lg:w-8 lg:h-8" fill="black" 
                    src="/svg/plus.svg" />
            </button>
        </div>
       <!-- filters -->


       <!-- TIPO IMOVEL -->
        <div class="overflow-scroll flex-grow px-2 lg:px-8 mt-4 lg:mt-8">

            <!-- REFERENCIA -->
            <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
                <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                    {{ $processLanguage(advancedFiltersData.reference) }}
                </p>
            </div>
            <div class="px-2 baselg:px-8 rounded-lg bg-white flex font-euclid mt-4 w-full">
                <div class="relative flex inline border rounded-lg border-black border-opacity-25 w-5/12 h-5 lg:h-full">
                    <input id="reference" name="reference" :placeholder="$t('developmentCard.reference')" ref="reference"
                        type="text"
                        @change="referenceChange"
                        class="font-euclid ml-2 h-4 lg:h-10 text-base placeholder-gray-500 text-black text-opacity-75 w-full"   />
                </div>
            </div>
            <!-- FIM REFERENCIA -->
            

            <div class="-mt-4 lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>

            <div class="px-2 baselg:px-8 rounded-lg  bg-white flex font-euclid mt-4 w-1/2" >
                <FilterDropdown
                    :items="computedHouseTypes.content"
                    :roundAllCorners="true"
                    blockType="houseType"
                    @on-change="onChangeHouseTypeBlock"
                    :myPlaceholder="computedHouseTypes.selected == 'All' ? $t('listingsPage.advancedFilters.dropdownPlaceholders.houseType') : getExtraPlaceholderName(computedHouseTypes.selected)"/>
            </div>
            <div v-if="computeExtraOptions" class="px-2 baselg:px-8 bg-white flex font-euclid text-baselg mt-4 lg:mt-8 propery_types-extraOptions">
                <CheckBox checkBoxclass="text-baselg font-euclid" @on-change="extraOptionChange('houseType', $processLanguage(item))" v-for="item of advancedFiltersData.property_type.extraOption"
                    :checkedValue="extraOptionSelected && houseTypesSelected == $processLanguage(item) ? true : null" 
                    :ref="$processLanguage(item)" 
                    :inputID="$processLanguage(item)" 
                    blockType="houseType" 
                    :key="$processLanguage(item)"
                    :labelText="$processLanguage(item)"/>      
                    
            </div>
            <div class="px-2 baselg:px-8 bg-white flex font-euclid text-baselg mt-4 lg:mt-8">
                <CheckBox checkBoxclass="text-baselg font-blackMango text-center font-bold tracking-wider uppercase" 
                class="flex items-center" @on-change="onChange" 
                    :checkedValue="advancedFiltersData.collection_prive.value_checked" inputID="collection" blockType="collection" 
                    :labelText="$processLanguage(advancedFiltersData.collection_prive)"/>                
            </div>
            <div class="px-2 baselg:px-8 font-euclid text-black text-opacity-50 text-sm mt-2">
                {{$processLanguage(advancedFiltersData.collection_prive_msg)}}
             </div>
            <div class="px-2 baselg:px-8 col-span-5 bg-white flex font-euclid text-baselg mt-4 lg:mt-8">
                <CheckBox checkBoxclass="text-baselg font-euclid" inputID="condominiums" blockType="condominiums" @on-change="onChange" 
                :labelText="$processLanguage(advancedFiltersData.condominiums)" :checkedValue="advancedFiltersData.condominiums.value_checked"/>                  
            </div>
            
            <div v-if="segmentSelected" class="px-2 baselg:px-8 col-span-5 bg-white flex font-euclid text-baselg mt-4 lg:mt-8">
                <CheckBox checkBoxclass="text-baselg font-euclid" inputID="segmentCheck" blockType="0" @on-change="uncheckSegment" 
                :labelText="$t('segment') + getSegmentText" :checkedValue="true"/>             
                             
            </div>
        <!-- END -->

        <div class="-mt-4 lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>

         <!-- PREÇO -->
            <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
                <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                    {{ $processLanguage(advancedFiltersData.pricing) }}
                </p>
            </div>
            <div class="px-2 baselg:px-8 col-span-5 rounded-lg bg-white flex">
                <MinMaxInput 
                    @on-change="onChange" 
                    blockType="pricing"
                    :min="computePricing.min"
                    :max="computePricing.max"
                  />
            </div>
        <!-- END -->

        <div class="-mt-4 lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>




        <!-- AREA BRUTA -->
            <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
                <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                    {{ $processLanguage(advancedFiltersData.gross_area) }}
                </p>
            </div>
            <div class="px-2 baselg:px-8 col-span-5 rounded-lg bg-white flex">
                <MinMaxInput
                    @on-change="onChange" 
                    blockType="gross" 
                    :min="computeGrossArea.min"
                    :max="computeGrossArea.max"
                    symbol="m2"/>
            </div>
        <!-- END -->

        <div class="lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>





        <!-- QUARTOS -->
            <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
                <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                    {{ $processLanguage(advancedFiltersData.bedrooms) }}
                </p>
            </div>
            <div class="px-2 baselg:px-8 col-span-5 w-full lg:w-4/6 rounded-lg bg-white flex ">
                <ButtonsList
                @on-change="onChange" class="rounded-l-lg cursor-pointer " blockType="beds" :items="computedBeds.content" :selectedButton="computedBeds.selected.split('+')[0]"/>
            </div>
        <!-- END -->

        <div class="lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>

        <!-- WCS -->
            <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
                <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                    {{ $processLanguage(advancedFiltersData.wcs) }}
                </p>
            </div>
            <div class="px-2 baselg:px-8 col-span-5 w-full lg:w-4/6 rounded-lg bg-white flex">
                <ButtonsList
                @on-change="onChange" class="rounded-l-lg cursor-pointer " blockType="wcs" :items="computedWCs.content" :selectedButton="computedWCs.selected.split('+')[0]"/>
                
            </div>
        <!-- END -->

        <div class="lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>


        <!-- PARKING -->
            <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
                <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                    {{ $processLanguage(advancedFiltersData.parking) }}
                </p>
            </div>
            <div class="px-2 baselg:px-8 col-span-5 w-full lg:w-4/6 rounded-lg bg-white flex">
                <ButtonsList
                @on-change="onChange" class="rounded-l-lg cursor-pointer " blockType="parking" :items="computedParking.content" :selectedButton="computedParking.selected.split('+')[0]" />
                
            </div>
        <!-- END -->

        <div class="lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>

        <!-- FLOOR -->
            <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
                <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                    {{ $processLanguage(advancedFiltersData.floor) }}
                </p>
            </div>
            <div class="px-2 baselg:px-8 col-span-5 w-full lg:w-4/6 rounded-lg bg-white flex">
                <ButtonsList
                @on-change="onChange" class="rounded-l-lg cursor-pointer " blockType="floor" :items="computedFloor.content" :selectedButton="computedFloor.selected.split('+')[0]"/>
                
            </div>

            <!-- ELEVATOR -->
                <div class="mt-8 px-2 baselg:px-8 col-span-5 bg-white flex text-baselg font-euclid">
                    <CheckBox @on-change="onChange" inputID="elevator" blockType="elevator" 
                    :labelText="advancedFiltersData.elevator.pt"
                    :checkedValue="advancedFiltersData.elevator.value_checked"/>
                                        
                </div>
            <!-- END -->
        <!-- END -->

        <div class="lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>

        <!-- ENERGY SLIDER -->
            <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
                <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                    {{ $processLanguage(advancedFiltersData.energy) }}
                </p>
            </div>
            <div class="px-2 baselg:px-8 col-span-5 rounded-lg bg-white flex mt-4">
                <Slider @on-change="onChange" blockType="energy"
                    :min="computeEnergy.range_value[0]"
                    :max="computeEnergy.range_value[1]"
                />
            </div>
        <!-- END -->

        <div class="mt-6 lg:h-8 md:h-10 h-6 border-b border-lightgray flex-none px-2 md:px-8 flex justify-between items-center w-full"></div>

         <!-- OTHERS -->
        <div class="mt-4 lg:h-8 baselg:h-10 h-6 flex-none px-2 baselg:px-8 flex justify-between items-center">
            <p class="text-baselg font-euclid font-bold uppercase tracking-wide">
                {{ $processLanguage(advancedFiltersData.others) }}
            </p>
        </div>
        <div class="col-span-5 rounded-lg bg-white flex" style="padding: 10px;margin-top: 10px;">
            <Features @on-change="onChange" blockType="others" :checkedFeatures="computeOthers.data"/>
        </div>
        <!-- END -->

       
        </div>

        <!-- buttons -->
        <div class="lg:h-16 md:h-10 h-6 border-t-2 border-lightgray rounded-b-xl flex items-center flex-none px-2 md:px-8">
            <button @click="removeAllFilters"
                class=" font-bold uppercase text-sm md:text-base tracking-widest inline-flex items-center justify-center  w-1/2">
                <span>{{ $t('advancedFilters.removeFilters')}} ({{ computeFiltersQty }})</span>
            </button>
            <button @click="openFilters"
                class="py-1 px-2 md:py-2 font-bold uppercase text-sm rounded-md md:text-base tracking-widest inline-flex items-center justify-center bg-black text-white w-1/2">
                <span>{{ $t('advancedFilters.see')}} {{ computeTotalResults }} {{ $t('advancedFilters.results')}}</span>
            </button>
        </div>
    </div>
</template>
<style scoped>
.propery_types-extraOptions{
  display: flex;
  justify-content: space-between;
  max-width: 27rem;
}
.h-screen{
    height: calc(var(--vh, 1vh) * 100);
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch;
    
}
.z-40{
    z-index:70;
}
</style>
<script>
import ButtonsList from "@/components/listings/filters/inputs/ButtonsList.vue";
import MinMaxInput from "@/components/listings/filters/inputs/MinMaxInput.vue";
import Features from "@/components/listings/filters/inputs/Features.vue";
import CheckBox from "@/components/listings/filters/inputs/CheckBox.vue";
import Slider from "@/components/listings/filters/inputs/Slider.vue";
import FilterDropdown from "@/components/listings/filters/inputs/dropdowns/FilterDropdown.vue";
//import iText from '@/components/contact/types/iText';


export default {
    name: "AdvancedFiltersBar",
    components: {
        ButtonsList,
        MinMaxInput,
        Features,
        CheckBox,
        Slider,
        FilterDropdown,
        //iText,
    },
    props: ['saleRentingDrop', 'beds', 'houseTypes', 'wcs', 'parking', 'floor', 'filters', 'filtersQty', 'totalResults'],
    data: () => ({
            advancedFiltersData :[],
            houseTypesSelected: 'All',
            extraOptionSelected: false,
            activeFilterType: false,
            componentExtraChecked: null,
            segmentSelected: false
        }),
    setup(){
        let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    created(){
        this.houseTypesSelected = this.filters.find(p => p.id === 'houseType').data;

        let linkData = this.$route.params.data

        if(linkData.includes('segment:')){
            this.segmentSelected = true
        }
        
        if(this.getExtraOptions()){
            this.extraOptionSelected = true
            this.activeFilterType = true
        }

        let rearrageHouseType = this.housingTypesFilter()
        this.advancedFiltersData = 
            {
                property_type: {
                    pt: "Tipo de imóvel",
                    en: "Type",
                    content: rearrageHouseType,
                    selected: this.filters.find(p => p.id === 'houseType').data,
                    extraOption:[
                      {
                        pt: "Loft",
                        en: "Loft",
                        // selected: this.filters.find(p => p.id === 'houseType').data,
                      },
                      {
                        pt: "Duplex",
                        en: "Duplex Home",
                        // selected: this.filters.find(p => p.id === 'houseType').data,
                      },
                      {
                        pt: "Penthouse",
                        en: "Penthouse",
                        // selected: this.filters.find(p => p.id === 'houseType').data,
                      }
                    ]
                },
                collection_prive:{
                    pt: "Collection Privée",
                    en: "Collection Privée",
                    pt_msg: "Descubra a nossa coleção de imóveis de luxo, um exclusivo LA",
                    en_msg: "Discover our collection of luxuous properties, LA exclusive",
                    value_checked: this.filters.find(p => p.id === 'collection').data
                },
                collection_prive_msg:{
                    pt: "Descubra a nossa coleção de imóveis de luxo, um exclusivo LA",
                    en: "Discover our collection of luxuous properties, LA exclusive",
                },
                condominiums:{
                    pt: "Empreendimentos",
                    en: "New developments",
                    value_checked: this.filters.find(p => p.id === 'condominiums').data
                },
                bedrooms: {
                    pt: "Quartos",
                    en: "Bedrooms",
                    content: this.beds,
                    selected: this.filters.find(p => p.id === 'beds').data
                },
                pricing: {
                    pt: "Valor do Imóvel",
                    en: "Price",
                    min: this.filters.find(p => p.id === 'pricing').data.min,
                    max: this.filters.find(p => p.id === 'pricing').data.max
                },
                gross_area:{
                    pt: "Área Bruta",
                    en: "Gross Area",
                    min: this.filters.find(p => p.id === 'gross').data.min,
                    max: this.filters.find(p => p.id === 'gross').data.max
                },
                wcs:{
                    pt: "WCs",
                    en: "WCs",
                    content: this.wcs,
                    selected: this.filters.find(p => p.id === 'wcs').data
                },
                parking:{
                    pt: "Lugares de estacionamento",
                    en: "Parking places",
                    content: this.parking,
                    selected: this.filters.find(p => p.id === 'parking').data
                },
                floor:{
                    pt: "Piso",
                    en: "Floor",
                    content: this.floor,
                    selected: this.filters.find(p => p.id === 'floor').data
                },
                energy:{
                    pt: "Eficiência Energética",
                    en: "Energy Efficiency",
                    range_value: this.filters.find(p => p.id === 'energy').data
                },
                elevator:{
                    pt: "Elevador",
                    en: "Elevator",
                },
                reference:{
                    pt:"Referência",
                    en:"Reference",
                },
                others:{
                    pt: "Outros",
                    en: "Others",
                    data: this.filters.find(p => p.id === 'others').data
                }
            }
        
    },
    methods: {
        referenceChange(a){
            this.$emit('on-change', 'reference', a.target.value)
        },
        uncheckSegment(){
            this.$emit('uncheck-segment')
        },
        getExtraPlaceholderName(pl){
            let flag = false

            if(pl == 'Loft')
                flag = true

            if(pl == 'Duplex')
                flag = true

            if(pl == 'Duplex Home')
                flag = true

            if(pl == 'Penthouse')
                flag = true

            let aux = {
                    pt: 'Apartamento',
                    en: 'Apartment'
                }
            if(flag)
                return this.$processLanguage(aux)
            else
                return pl

        },
        housingTypesFilter(){
            let content = this.houseTypes
            let finalArray = []
            content.forEach(element => {
                if(element.filterable == 1)
                    finalArray.push(element)    
            });
            return finalArray
        },
        openFilters() {
            this.$emit('open-filters')
        },
        onChange(block_type, data){
            this.houseTypesSelected = data;
            this.$emit('on-change', block_type, data)
        },
        onChangeHouseTypeBlock(block_type, data){
            this.houseTypesSelected = data;
            this.showExtraOptions()
            this.$emit('on-change', block_type, data)
        },
        checkSelectedCoponents(newComp){
            Object.keys(this.$refs).forEach(el => {
                let component = this.$refs[el][0]
                if(component.labelText != newComp){
                    component.deactivateBox()
                }else component.activateBox()
            })
        },
        checkActiveBoxes(){
            let counter = 0
            Object.keys(this.$refs).forEach(el => {
                let component = this.$refs[el][0]
                if(component.getActiveValue())
                    counter++
            })

            return counter
        },
        extraOptionChange(block_type, data){
            if(this.houseTypesSelected != data)
                if(this.componentExtraChecked != data)
                    this.checkSelectedCoponents(data)

            this.houseTypesSelected = data;

            this.componentExtraChecked = data
           
            if(this.checkActiveBoxes() == 0)
                this.activeFilterType = false
            else
                this.activeFilterType = true


            if(this.activeFilterType == false){
                let aux = {
                    pt: 'Apartamento',
                    en: 'Apartment'
                }
                this.$emit('on-change', block_type, this.$processLanguage(aux))
            }else{
                this.$emit('on-change', block_type, data)
            }
        },
        removeAllFilters(){
            this.openFilters();
            this.$emit('remove-filters')
        },
        showExtraOptions(){

            let flag = false
            if(this.houseTypesSelected == 'Apartment')
                flag = true
                
            if(this.houseTypesSelected == 'Apartamento')
                flag = true

            if(this.houseTypesSelected == 'Loft')
                flag = true

            if(this.houseTypesSelected == 'Duplex')
                flag = true

            if(this.houseTypesSelected == 'Duplex Home')
                flag = true

            if(this.houseTypesSelected == 'Penthouse')
                flag = true

            if(flag){
                this.extraOptionSelected = true
            }else{
                this.extraOptionSelected = false
            }

        },
        getExtraOptions(){

            let flag = false
            if(this.houseTypesSelected == 'Apartment')
                flag = true
                
            if(this.houseTypesSelected == 'Apartamento')
                flag = true

            if(this.houseTypesSelected == 'Loft')
                flag = true

            if(this.houseTypesSelected == 'Duplex')
                flag = true

            if(this.houseTypesSelected == 'Duplex Home')
                flag = true

            if(this.houseTypesSelected == 'Penthouse')
                flag = true

            return flag

        }
    },
    computed: {
        getSegmentText(){
            let linkData = this.$route.params.data

            if(linkData.includes('segment:')){
                linkData = linkData.split('segment:')[1]
            }

            return linkData
        },
        computeExtraOptions(){
            return this.extraOptionSelected
        },        
        computedHouseTypes(){
            return this.advancedFiltersData?.property_type
        },
        computedBeds(){
            return this.advancedFiltersData.bedrooms
        },
        computedWCs(){
            return this.advancedFiltersData.wcs
        },
        computedParking(){
            return this.advancedFiltersData.parking
        },
        computedFloor(){
            return this.advancedFiltersData.floor
        },
        computeFiltersQty(){
            return this.filtersQty
        },
        computePricing(){
            return this.advancedFiltersData.pricing
        },
        computeGrossArea(){
            return this.advancedFiltersData.gross_area
        },
        computeEnergy(){
            return this.advancedFiltersData.energy
        },
        computeOthers(){
            return this.advancedFiltersData.others
        },
        computeFilters(){
            return this.filters
        },
        computeTotalResults(){
            return this.totalResults
        }
    }
}
</script>

