<template>
        <div class="flex bg-white rounded-lg h-5 lg:h-8 w-full relative items-center border border-gray lg:border-0">
            <!-- DESKTOP -->
            <inline-svg class="hidden md:block absolute inset-y-0 my-auto left-1" height="1.5rem" width="1.5rem"
                src="/svg/search.svg" />
            <!-- END DESKTOP -->

            <!-- MOBILE -->
            <inline-svg class="sm:hidden absolute inset-y-0 my-auto left-1" height="1rem" width="1rem"
                src="/svg/search.svg" />
            <!-- END MOBILE -->


            <gmap-autocomplete 
                @place_changed="setPlace"
                ref="myGmapAutocomplete"
                id="myGmapAutocomplete"
                :options='{
                        componentRestrictions: { country: "pt" },
                        fields: ["address_components", "geometry", "icon", "name"],
                        strictBounds: false,
                        types: ["geocode"],
                    }'
                :value="computeCityVisiting"
                :placeholder="$t('GOOGLE_MAP.search_placeholder')"
                class="bg-white appearance-none rounded-lg block py-2 h-full w-full pl-6 lg:pl-8 focus:ring-1 focus:ring-bronze">
            </gmap-autocomplete>
        </div>
</template>
<script>
export default {
    name: "InputLocation",
    props:{
        cityVisiting: String
    },
    methods:{
        setPlace(place) {
            this.$emit('input-location-changed', place)
        }
    },
    computed:{
        computeCityVisiting(){
            if(this.cityVisiting == '')
                return ''
                
            return this.cityVisiting + ', Portugal'
        }
    }
}
</script>
