<template>
  <div :key="reloadDiv">
      <div class="gmapLocationSearch gmap-principal-div font-bold text-base rounded-lg border border-solid border-bronze" v-show="false">
      <h2 class="ml-4 mt-4">Search location</h2>
      <div class="-mt-6 lg:h-8 md:h-10 h-6 border-b-2 border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"></div>
        <gmap-autocomplete 
          @place_changed="setPlace"
          :options='{
                        componentRestrictions: { country: "pt" },
                        fields: ["address_components", "geometry", "icon", "name"],
                        strictBounds: false,
                        types: ["geocode"],
                    }'
          style="background-color: white; width: 70%"
          class="text-baselg font-euclid ml-4 mt-4 font-euclid w-full h-10 bg-transparent text-base placeholder-gray-500 transition-opacity border-opacity-25 focus:shadow-outline focus:border-opacity-100">
          </gmap-autocomplete>
         
      </div>
      
      <GmapMap id="gMap"
          :center="center"
          ref="map"
          :zoom="zoomValue"
          @zoom_changed="filterMarkers"
          @dragend="filterMarkers"          
          map-type-id="roadmap"
          style="width: 100%; height: 300px; margin-left: auto; margin-right: auto; position: unset;"
          :options="getOptions"
          >          
          
          <GmapCircle
            v-for="(m, i) in items"
            :key="'Pos: ' + i"
            :center="m.position"
            :radius="m.radius"
            :visible="true"
            :options="selectedMark==m.id ? 
                      {
                        strokeColor: '#000000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#000000',
                        fillOpacity:0.35
                      } : 
                      { 
                        strokeColor: '#BE5E3B',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#BE5E3B',
                        fillOpacity:0.35
                      }"
            @click="showTooltip(m)"
          />
          <!--
          <GmapCircle
            v-for="(m, i) in items"
            :key="'PosCenter: ' + i"
            :center="m.position"
            :radius="m.radius/6"
            :visible="true"
            :options="selectedMark==m.id ? 
                      {
                        strokeColor: '#000000',
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: '#000000',
                        fillOpacity:0.35
                      } : 
                      { 
                        strokeColor: '#BE5E3B',
                        strokeOpacity: 0.8,
                        strokeWeight: 0,
                        fillColor: '#BE5E3B',
                        fillOpacity:1
                      }"
            @click="showTooltip(m)"
          />-->
          <GmapInfoWindow
            v-if="InformationWindowTemplateData.showInfoTemplate"
            :options="{maxWidth: 400}"
            :position="infoWindow.position"
            :opened="infoWindow.open"
            @closeclick="infoWindow.open=false"
          >
            <div class="overflow-hidden flex flex-col" v-click-outside='closeGmapInfoWindow'>
                <div class="relative">
                  <div class="absolute left-2 bottom-2 md:left-3 md:bottom-3 z-10 flex justify-center items-center rounded-md md:rounded-lg p-0 h-5 md:h-auto px-2 md:py-1 md:px-4 glass-effect glass-fallback">
                    <p class="text-darkgray text-base font-semibold">{{ InformationWindowTemplateData.selectedMarkerPrice }}</p>
                  </div>
                  <div class='overflow-hidden listing-card rounded-lg hover:border-bronze border-2 transition-all duration-300 border-transparent lg:rounded-2xl w-full'>
                <img 
                  class='lg:rounded-xl rounded-lg cursor-pointer object-center w-full h-full object-cover hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform'
                  @click="generateLink(InformationWindowTemplateData.selectedMarkerID)"
                  :src='this.$mediaFile("properties", InformationWindowTemplateData.selectedMarkerID , 
                    InformationWindowTemplateData.selectedMarkerCover)'
                  :lazy-src='this.$mediaFile("properties", InformationWindowTemplateData.selectedMarkerID , 
                    InformationWindowTemplateData.selectedMarkerCover)'>
                </div>
              </div>
              <div class="flex space-x-1 md:space-x-2 lg:space-x-3 justify-center">
                <PropertyDetailsBig homepage :number='InformationWindowTemplateData.bedrooms' img="/svg/houseDetails/rooms.svg" :color="color" />
                <PropertyDetailsBig homepage :number='InformationWindowTemplateData.bathrooms' img="/svg/houseDetails/baths.svg" :color="color" />
                <PropertyDetailsBig homepage :number='InformationWindowTemplateData.parking' img="/svg/houseDetails/garage.svg" :color="color" />
                <PropertyDetailsBig homepage :number='InformationWindowTemplateData.area' img="/svg/houseDetails/area.svg" :color="color" :measure="$t('propertyCard.area')"/>
              </div>
            </div>
          </GmapInfoWindow>
      </GmapMap>
  </div>
</template>
<style>
.gm-style-iw > button {
  display: none !important;
}
.gm-svpc{
  display: none !important;
}
.gmapLocationSearch{
  z-index: 20; 
  position: absolute;
  background-color: white;
  width: 200px;
}
.principal-div {
  background-color: white;
  position: absolute;
  left: 60%;
  z-index: 20;
  top: 20%;
  min-width: 350px;
  height: 100px;
}
</style>

<script>
import mixin from '@/models/general/GeneralModel'
import PropertyDetailsBig from "@/components/listings/cards/PropertyDetailsBig";

export default {
  name: "GoogleMap",
  mixins: [mixin],
  components: {
    PropertyDetailsBig
  },
  props: ['properties_items', 'currentPlace', 'searchedPlace'],
  data() {
    return {
      color: "darkgray",
      center: {lat:38.69690000001411, lng:-9.420450000000004},
      markers: [],
      selectedMark: -1,
      markerChild: {
        lat: 0,
        lng: 0,
        radius: 25
      },
      newMarkers: [],
      showSaver: false,
      showRemover: false,
      InformationWindowTemplateData: {
        selectedMarkerID: 0,
        selectedMarkerCover: '',
        selectedMarkerPrice: 0,
        showInfoTemplate: false,
        isOpened: false,
        bathrooms: 0,
        bedrooms: 0,
        parking: 0,
        area: 0
      },      
      toDeleteMarkers: [],
      zoomValue: 13,
      propertiesToDisplay: [],
      places: [],
      reloadDiv: 0,
      block: 'location',
      infoWinOpen: false,
      infoWindow: {
          position: {lat: 0, lng: 0},
          open: false,
          template: ''
        }
    };
  },
  async mounted() {
    if(this.currentPlace == null)
      this.geolocate();    

  },
  async created(){
    await this.getMapMarkers()
    //await this.filterMarkers()
    if(this.currentPlace != null){
      this.center = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng()
      }
    }
    
    if(this.searchedPlace)
      await this.filterMarkers()
  },
  computed: {
    getOptions(){
      let options = {
            clickableIcons: false,
            mapTypeControl: false, 
            fullscreenControl: false,
            styles: [
              {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
              },
              {
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#f5f5f5"
                  }
                ]
              },
              {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#bdbdbd"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#ffffff"
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#757575"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#dadada"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#616161"
                  }
                ]
              },
              {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              },
              {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#e5e5e5"
                  }
                ]
              },
              {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#eeeeee"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#c9c9c9"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#9e9e9e"
                  }
                ]
              }
            ]
          }


      return options
    },
    items() {
      let retrievedData = this.$store.state.generalModule.markers
      let returningArray = []
      if(retrievedData == null)
        return null;

      
      retrievedData.forEach(element => {

        // need to check if the marks are with the properties filter
        this.properties_items.forEach(elProperty => {
          if(elProperty.id == element.property_id){
            let marker = {
              lat: parseFloat(element.lat),
              lng: parseFloat(element.len),
              radius: parseFloat(element.radius)
            };
            returningArray.push({id: element.id, position: marker, radius: parseFloat(element.radius), property_id: element.property_id})
        }
        });
          
          
         
      });
      return returningArray
    }
  },
  watch:{
    currentPlace(p){
      // Check if city is selected
      let linkData = this.$route.params.data
      if(linkData.includes('city:')){
        linkData = linkData.split('city:')[1]
        if(linkData == "Lisboa" || linkData == 'Lisbon'){
          this.center ={
            lat: 38.736946,
            lng: -9.142685
          }
        }
        if(linkData == "Porto"){
          this.center ={
            lat: 41.157944,
            lng: -8.629105
          }
        }
        if(linkData == "Cascais"){
          this.center ={
            lat: 38.696758,
            lng: -9.420744
          }
        }
        if(linkData == "Algarve"){
          this.center ={
            lat: 37.017956,
            lng: -7.930834
          }
        }
        this.zoomValue = 10
      }else{
        this.currentPlace = p
        this.center = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng()
        }
      }
    }
  },
  methods: {
    filterMarkers: function(){
      const b = this.$refs.map.$mapObject.getBounds();
    
      let retrievedData = this.$store.state.generalModule.markers
      let finalFilter = []
      retrievedData.forEach(element => {
        let dealerPosition = {};
        dealerPosition = { 
            position: { 
                lat: parseFloat(element.lat), 
                lng: parseFloat(element.len)
            } 
        };
          
        let m = new window.google.maps.Marker( dealerPosition );
        
        if( b.contains( m.getPosition() ) === true){
          finalFilter.push(element);
        }
      });
      this.propertiesToDisplay = finalFilter
      this.$emit('changed-location', this.propertiesToDisplay)
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },    
    setPlace(place) {
      this.currentPlace = place;
      this.center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
    },
    generateLink(id){
        this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
    },
    showTooltip(m){
      this.infoWindow.position = m.position
      this.infoWindow.open = true
      let parent = this
      this.properties_items.forEach(p => {        
        if(p.id == m.property_id){
          this.InformationWindowTemplateData.selectedMarkerCover = p.media.cover
          this.InformationWindowTemplateData.selectedMarkerID = p.id
          
          if(p.hide_price == 1)
            this.InformationWindowTemplateData.selectedMarkerPrice = parent.$t('price_under_request')
          else
            this.InformationWindowTemplateData.selectedMarkerPrice = parent.$currency(p.price)
          
          this.InformationWindowTemplateData.parking = p.parking
          this.InformationWindowTemplateData.area = p.kpis.m2
          this.InformationWindowTemplateData.bathrooms = p.kpis.baths
          this.InformationWindowTemplateData.bedrooms = p.kpis.beds
        }
      })

      this.InformationWindowTemplateData.showInfoTemplate = true
    },
    closeGmapInfoWindow(){
      if(this.InformationWindowTemplateData.isOpened){
        this.InformationWindowTemplateData.isOpened = false
        this.InformationWindowTemplateData.showInfoTemplate = false
      }else{
        this.InformationWindowTemplateData.isOpened = true
      }
    }
  }
};
</script>

 
