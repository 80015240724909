<template>
    <div class="h-screen bg-gray relative">
    
    <div class="pt-20 w-full mb-8 px-16">
        <GoogleMap :key="propertiesListerMap" :searchedPlace="searchedPlace" :properties_items="propertiesFiltered" @changed-location="updateProperties" :currentPlace="getGoogleMapPlace"/>
    </div>
        
    <!-- listings bar -->
    <div id="map-listings-card"
        class="hidden md:flex mx-20 bg-white px-2 pb-3 pt-2 rounded-t-xl bottom-0 absolute flex flex-col justify-center">
        <ListingsInfoBar :results="listingFilter != null ? listingFilter.length : 0" @orderby="setOrderby"/>
        <div class="grid gap-5 gap-y-6 grid-cols-5">
            <a
                v-for="item in listingFilter" 
                :key="item.id"
                @click="generateLink(item.id)" 
            >
            <PropertyCardBig 
                :baths="new String(item.kpis.baths)"
                :rooms="new String(item.kpis.beds)"
                :garage="new String(item.parking)"
                :area="new String(item.kpis.m2)"
                :reference="item.reference"
                location=""
                cityAddress=""
                :img="$mediaFile('properties', item.id , item.media.cover)"
                :slogan="mapViewSlogan(new String($processLanguage(item.information.title)))"
                :tag="item.tag" 
                :price="item.hide_price == 1 ? $t('price_under_request') : $currency(new String(item.price))" 
                sizeImg="h-img-medium-card"
                :isListingView="true"
                inMap
                color="darkgray"
                :dev_id="item.id"/>
            </a>
        </div>
    </div>
    </div>
</template>
<style scoped>
#map-listings-card{
  padding-inline: calc(1.5rem + 0.5vw);
  border-radius: 30px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
<script>
    import PropertyCardBig from "@/components/listings/cards/PropertyCardBig";
    import ListingsInfoBar from "@/components/listings/ListingsInfoBar";
    import GoogleMap from "@/components/listings/filters/inputs/GoogleMaps"
    import EnergyEfficiency from '@/data/Energy'

    export default {
        name: "ListingsView",
        props: ['properties_items', 'place', 'filters', 'searchedPlace'],
        components: {
            PropertyCardBig,
            ListingsInfoBar,
            GoogleMap         
        },
        data: function () {
            return {
                allEnergy: EnergyEfficiency,
                listingsList: 5,
                propertiesList: null,
                orderby: 'most_recent',
                propertiesFiltered: null,
                propertiesListerMap: 0
            }
        },
        created(){
            this.propertiesFiltered = this.properties_items
        },
        methods:{
            mapViewSlogan(sl){
                let position = 20
                
                for(var i = position; i < sl.length; i++){
                    let ch = sl.charAt(i)
                    if(ch == " "){
                        position = i
                        break;
                    }
                }                
                if(position > 20)
                    return sl.slice(0, position) + '...'
                
                return sl.slice(0, position)
            },
            getCityAddress(i){
                if(i.administrative_area_level_2 == '' || i.administrative_area_level_2 == null)
                    return ''
                
                if(i.political != null)
                    return i.political + ', ' + i.administrative_area_level_3

                return i.administrative_area_level_2 + ', ' + i.administrative_area_level_3
            },
          setOrderby(value){
            this.orderby = value;
          },
            updateProperties: function(list){
                let myNewList = []
                list.forEach(property => {
                    this.properties_items.forEach(item => {
                        if(property.property_id == item.id)
                            if(myNewList.indexOf(item) == -1)
                                myNewList.push(item)
                    })    
                })

                this.propertiesList = myNewList
                this.$emit('filter-properties-list', this.propertiesToDisplay)
            },
            generateLink(id){
                this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
            },
            // Filter properties filter list
            applyPropertieFilters(){
                let list = []
                let filtersList = this.filters
                let parent = this
                if(this.properties_items == null)
                    return []
                this.properties_items.forEach(function(key){      
                    let flag = 0
                    
                    
                    // FOR SALE OR RENTING
                    

                    // Se estivermos a procura de vender
                    if(filtersList.find(p => p.id === "saleRenting").data === parent.$t('listingsPage.for_sale_rent.for_sale')){
                        if(key.for_sale == 0)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    // Se estivermos a procura de arrendar
                    if(filtersList.find(p => p.id === "saleRenting").data === parent.$t('listingsPage.for_sale_rent.for_rent')){
                        if(key.for_sale == 1)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    // Look for Area
                    /*if(flag === 0){
                        if(parent.place != null){
                            parent.place.address_components.forEach(addr => {
                                if(addr.types[0] == "administrative_area_level_1")
                                    if(key.gmap_district == addr.long_name)
                                        flag = 0
                                    else
                                        flag = 1
                            });
                        }else
                            flag = 0
                    }*/
                    // FIM
                    

                    // COLLECTION
                    
                    if(flag === 0){
                        if(filtersList.find(p => p.id == "collection").data === false)
                            flag = 0
                        else if((key.collection ? 1 : 0) == filtersList.find(p => p.id === "collection").data)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM
                    
                     // PROPERTIES BELONGING TO CONDOMINIUMS
                    if(flag === 0){
                        if(filtersList.find(p => p.id == "condominiums").data === false)
                            flag = 0
                        else if((key.condominium_id != undefined ? 1 : 0) == filtersList.find(p => p.id === "condominiums").data)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    // ELEVATOR
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "elevator").data === true)
                            flag = 0
                        else if(key.elevator === filtersList.find(p => p.id === "elevator").data)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    

                    // TIPO IMOVEL
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "houseType").data === "All")
                            flag = 0
                        else if(parent.$processLanguage(key.type_name) === filtersList.find(p => p.id === "houseType").data)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM
                    

                    // PREÇO
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "pricing").label === "")
                            flag = 0
                        else if(key.price >= filtersList.find(p => p.id === "pricing").data.min && key.price <= filtersList.find(p => p.id === "pricing").data.max)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    // Area Bruta                    
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "gross").label === "")
                            flag = 0
                        else if(key.kpis.m2 >= filtersList.find(p => p.id === "gross").data.min && key.kpis.m2 <= filtersList.find(p => p.id === "gross").data.max)
                            flag = 0
                        else
                            flag = 1
                    }

                    // FIM
                    
                    // Quartos
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "beds").data === "----")
                            flag = 0
                        else if(filtersList.find(p => p.id === "beds").data == "All")
                            flag = 0
                        else if(filtersList.find(p => p.id === "beds").data.slice(0, 1) == 5)
                            if(key.kpis.beds >= filtersList.find(p => p.id === "beds").data.slice(0, 1))
                            flag = 0
                                else
                            flag = 1
                        else if(key.kpis.beds == filtersList.find(p => p.id === "beds").data.slice(0, 1))
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim



                    // WCS
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "wcs").data === "----")
                            flag = 0
                        else if(key.kpis.baths >= filtersList.find(p => p.id === "wcs").data.slice(0, 1))
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim


                    // ESTACIONAMENTO
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "parking").data === "----")
                            flag = 0
                        else if(key.parking >= filtersList.find(p => p.id === "parking").data.slice(0, 1))
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim



                    // PISO
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "floor").data === "----")
                            flag = 0
                        else if(key.floor >= filtersList.find(p => p.id === "floor").data.slice(0, 1))
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim


                    // ENERGIA
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "energy").data === "")
                            flag = 0
                        else if(key.energy == "")
                            flag = 0
                        else if(key.energy == null)
                            flag = 0
                        else if(parent.energyConverter(key.energy) >= filtersList.find(p => p.id === "energy").data[0] && parent.energyConverter(key.energy) <= filtersList.find(p => p.id === "energy").data[1])
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim

                    

                     // OTHERS
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "others").data.length === 0)
                            flag = 0
                        else if(key.features_id != ''){
                            let internalFlag = 1
                            let splitFeatures = key.features_id.split(',')
                            filtersList.find(p => p.id === "others").data.forEach(function(key){  
                                if(splitFeatures.indexOf(key) !== -1)
                                    internalFlag = 0
                            })
                            if(internalFlag == 1)
                                flag = 1
                        }
                        else
                            flag = 1
                    }
                    // Fim



                        
                    if(flag === 0)
                        list.push(key)          
                })

                this.propertiesFiltered = list
                return list
            },
            energyConverter(energy){
                return this.allEnergy.indexOf(energy+"")
            }
        },
        computed:{
            listingFilter(){   
                if(this.propertiesList == null)
                    return null

                
                let list = this.applyPropertieFilters()
                list = list.slice(0, this.listingsList);
                this.orderby != 'most_recent' && (list = list.sort((p1, p2) => {
                  switch(this.orderby){
                    case 'price_lower_higher': {
                      return p1.price - p2.price;
                    }
                    case 'price_higher_lower': {
                      return p2.price - p1.price 
                    }
                    case 'largest_area': {
                      return p2.kpis.m2 - p1.kpis.m2
                    }
                  }
                }))

                return list
            },
            getGoogleMapPlace(){
                return this.place
            }
        },
        watch:{
            place(p){
                this.place = p
                this.propertiesFiltered = this.properties_items
            },
            propertiesFiltered(pf){
                this.propertiesFiltered = pf
            }
        }
    
    };
</script>