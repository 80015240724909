<template>
    <div >
        <input 
            :checked="computeChecked" 
            :class="checkedFlag ? 'w-3 h-3 lg:w-5 lg:h-5 bg-white rounded-full align-middle border border-bronze outline-none cursor-pointer appearance-none bg-bronze' : 'w-3 h-3 lg:w-5 lg:h-5 bg-white rounded-full align-middle border border-bronze outline-none cursor-pointer appearance-none' "
            type="checkbox" 
            :id="computeID" 
            :block_type="computeBlockType" 
            @input="onChange">
        <label :class="getClass" :for="inputID" style="margin-left: 10px;">{{labelText}}</label>        
    </div>
</template>
<script>
export default {
    name: "CheckBox",
        props: {
            inputID: String,
            blockType: String,
            labelText: String,
            checkedValue: Boolean,
            checkBoxclass: {
                String,
                default: ''
            }
        },
        data(){
            return{
                checkedFlag: false
            }
        },
        mounted(){
            this.checkedFlag = this.checkedValue
        },
        methods: {
            onChange(e){
                this.checkedFlag = !this.checkedFlag
                this.$emit('on-change', this.blockType, e)
            },
            getActiveValue(){
                return this.checkedFlag
            },
            deactivateBox(){
                this.checkedFlag = false
            },
            activateBox(){
                this.checkedFlag = true
            }
        },
        computed: {
            computeBlockType(){
                return this.blockType
            },
            computeID(){
                return this.inputID
            },
            computeChecked(){
                return this.checkedValue
            },
            getClass(){
                return this.checkBoxclass
            }
        }
}
</script>