<template>
    <div class="lg:bg-black rounded-b-3xl text-base font-medium block top-0 w-full md:w-11/12 mx-auto inset-x-0 z-40 p-4">
        <!-- DESKTOP -->
        <div class="hidden md:grid grid grid-cols-11 gap-2 text-base">
            <div class="col-span-3">
                <InputLocation :key="locationKey" @input-location-changed="inputLocationChanged" :cityVisiting="computeCityVisiting"/>
            </div>
            <div class="col-span-6 rounded-lg bg-white flex">
                
                <!-- NOT IN USE -->
                <FixedDropdown 
                    :myPlaceholder="filters.find(p => p.id === 'saleRenting').label" 
                    class="rounded-l-lg hover:border-bronze"
                    :borders="[1, 1, 1, 1]"
                    v-if="false"
                    @activate-me="activateDropdown(0)"
                    @disable-me="disableDropdown(0)"
                />
                <!-- END NOT IN USE COMPONENT -->

                <OptionsDropdown 
                    :myPlaceholder="filters.find(p => p.id === 'saleRenting').label" 
                    class="rounded-l-lg hover:border-bronze"
                    :borders="[1, 1, 1, 1]"
                    @activate-me="activateDropdown(0)"
                    @disable-me="disableDropdown(0)"
                    :disablePlaceholder="computeSaleRentingSaleDropdownPlaceholder"
                    @on-change="changedForSaleRent"
                />
            
                <FixedDropdown id="houseTypeDiv"
                    :myPlaceholder="computeHouseTypeDropdownPlaceholder" 
                    class="hover:border-bronze"
                    :borders="[1, 1, 0, 1]"
                    :disablePlaceholder="computeEnableHouseTypeDropdownPlaceholder"
                    @click.native="openHouseTypeDiv"
                    @activate-me="activateDropdown(1)"
                    @disable-me="disableDropdown(1)"
                />


                <FixedDropdown id="pricingDropdown"
                        :myPlaceholder="computePriceDropdownPlaceholder" 
                        class="hover:border-bronze"
                        :borders="[1, 1, 0, 0]"
                        :disablePlaceholder="computeEnablePriceDropdownPlaceholder"
                        @click.native="openPricingDiv"                        
                        @activate-me="activateDropdown(2)"
                        @disable-me="disableDropdown(2)"
                />

                <FixedDropdown id="bedsDropdown"
                        :myPlaceholder="computeBedsDropdownPlaceholder" 
                        class="hover:border-bronze rounded-r-lg"
                        :disablePlaceholder="computeEnableBedsDropdownPlaceholder"
                        @click.native="openBedroomDiv"
                        :borders="[1, 1, 1, 1]"
                        @activate-me="activateDropdown(3)"
                        @disable-me="disableDropdown(3)"
                    />

            </div>
            <div class="col-span-2 grid grid-cols-5 flex space-x-2 text-base">
                <MapSwitch v-if="!isMobile" class="col-span-3" ref="mapSwitchRef"/>
                <FiltersButton @click.native="openFilters" :advancedFiltersNumber="computeFiltersQty" class="col-span-2 cursor-pointer"  />
                <RemoveFilters v-if="false" class="col-span-5" @remove-filters="removeFilters" :filtersNumber="computeFiltersQty" />
            </div>
        </div>
        <!-- end:DESKTOP -->

        <!-- MOBILE -->
        <div class="sm:hidden mt-10 grid grid-cols-6 rounded-lg bg-white flex">
            
            <div class="col-span-6">
                <InputLocation @input-location-changed="inputLocationChanged" :cityVisiting="computeCityVisiting"/>
            </div>

            <div class="col-span-2 mt-2 flex justify-start">
            <OptionsDropdown 
                    :myPlaceholder="filters.find(p => p.id === 'saleRenting').label" 
                    class="rounded-lg hover:border-bronze"
                    :borders="[1, 1, 1, 1]"
                    @activate-me="activateDropdown(0)"
                    @disable-me="disableDropdown(0)"
                    :disablePlaceholder="computeSaleRentingSaleDropdownPlaceholder"
                    :mobile="true"
                    @on-change="changedForSaleRent"
                />
            </div>

            <div class="col-span-2 mt-2 flex justify-center">
                <MapSwitch v-if="isMobile" ref="mapSwitchRef"/>
            </div>

            <div class="col-span-2 mt-2 flex justify-end">
                <FiltersButton @click.native="openFilters" :advancedFiltersNumber="computeFiltersQty" />
            </div>
            
            
            
        </div>
        <!-- end:MOBILE -->
    </div>
    
</template>
<script>
import InputLocation from "@/components/listings/filters/inputs/InputLocation.vue";
import OptionsDropdown from "@/components/listings/filters/inputs/dropdowns/OptionsDropdown.vue";
import FixedDropdown from "@/components/listings/filters/inputs/dropdowns/FixedDropdown.vue";
import RemoveFilters from "@/components/listings/filters/bar/RemoveFilters.vue";
import FiltersButton from "@/components/listings/filters/bar/FiltersButton.vue";
import MapSwitch from "@/components/listings/filters/bar/MapSwitch.vue";

// Import models to use in our components
import mixin from '@/models/keys/KeyModel'
import { buildPayloadPagination } from '@/utils/utils'




export default {
    name: "FiltersMain",
    components: {
        InputLocation,
        RemoveFilters,
        FiltersButton,
        MapSwitch,
        FixedDropdown,
        OptionsDropdown
        },
        data: () => ({
            //target: 'features',
            target: 'types',
            formData: null,
            dropdownsActive: [1, 0, 0, 0],
            locationKey: 0,
            isMobile: false
        }),
        props: ['saleRentingDrop', 'beds', 'houseTypes', 'filters', 'filtersQty', 'cityVisiting'],
        mixins: [mixin],
        async created(){
            try{
                if(screen.width < 768) this.isMobile = true;
                await this.getKeyItems(
                    buildPayloadPagination({}, this.buildSearch(), this.target)
                )
            }catch(error){
                console.log(error)
            }
        },
        methods:{
            resetField(){
                this.locationKey++
            },
            changedForSaleRent(data){
                this.$emit('on-change', 'saleRenting', data)
            },
            activateDropdown(pos){
                this.dropdownsActive[pos] = 1
            },
            disableDropdown(pos){
                this.dropdownsActive[pos] = 0
            },
            removeFilters(){
                this.$emit('remove-filters')
            },
            changeMapSwitch(v){
                this.$refs.mapSwitchRef.changeSwitch(v)
            },
            inputLocationChanged(place){
                this.$emit('input-location-changed', place)
            },
            openFilters(){
                this.$emit('open-filters')
            },
            openPricingDiv(e){
                let bounds = document.querySelector("#pricingDropdown").getBoundingClientRect()
                this.$emit('open-price-div', e, bounds)
            },
            openHouseTypeDiv(e){
                let bounds = document.querySelector("#houseTypeDiv").getBoundingClientRect()
                this.$emit('open-house-type-div', e, bounds)
            },
            openBedroomDiv(e){
                let bounds = document.querySelector("#bedsDropdown").getBoundingClientRect()
                this.$emit('open-bedroom-div', e, bounds)
            },
            buildSearch() {
                return this.search
                ? { query: this.search, fields: this.fieldsToSearch.join(',') }
                : {}
            },
            onChange(block_type, data){
                this.$emit('on-change', block_type, data)
            },
            returnSelectedFilter(block_type){
                return this.filters.find(p => p.id === block_type).data
            },
            cleanPriceFilter(){
                this.$emit('clean-price-filter')
            }
        },
        computed:{
            computeCityVisiting(){
                return this.cityVisiting
            },
            computedSaleRentingDrop(){
                return this.saleRentingDrop
            },
            computedHouseTypes(){
                return this.houseTypes
            },
            computedBeds(){
                return this.beds
            },
            computeFiltersQty(){
                return this.filtersQty
            },
            // --------------------------
            // Bedrooms div listeners
            // --------------------------
            computeBedsDropdownPlaceholder(){
                if(this.filters.find(p => p.id === 'beds').data != 'All')
                    return this.$t('listingsPage.advancedFilters.dropdownPlaceholders.beds') + ": " + this.filters.find(p => p.id === 'beds').data

                return this.$t('listingsPage.advancedFilters.dropdownPlaceholders.beds')
            },
            computeEnableBedsDropdownPlaceholder(){
                if(this.filters.find(p => p.id === 'beds').data != 'All')
                    return false

                return true
            },
            // --------------------------
            // end bedrooms div listeners
            // --------------------------
            // Pricing div listeners
            // --------------------------
            computePriceDropdownPlaceholder(){
                if(this.filters.find(p => p.id === 'pricing').label != '-')
                    return this.filters.find(p => p.id === 'pricing').label
                    
                return this.$t('listingsPage.advancedFilters.dropdownPlaceholders.pricing')
            },
            computeEnablePriceDropdownPlaceholder(){
                if(this.filters.find(p => p.id === 'pricing').label != '-')
                    return false

                return true
            },
            // --------------------------
            // end pricing div listeners
            // --------------------------
            // House Type div listeners
            // --------------------------
            computeHouseTypeDropdownPlaceholder(){
                if(this.filters.find(p => p.id === 'houseType').data != 'All')
                    return this.filters.find(p => p.id === 'houseType').data
                    
                return this.$t('listingsPage.advancedFilters.dropdownPlaceholders.houseType')
            },
            computeEnableHouseTypeDropdownPlaceholder(){
                if(this.filters.find(p => p.id === 'houseType').data != 'All')
                    return false
                    
                return true
            },
            computeSaleRentingSaleDropdownPlaceholder(){
                if(this.filters.find(p => p.id === 'saleRenting').data != this.$t('listingsPage.for_sale_rent.for_sale'))
                    return false
                    
                return true
            }
            // --------------------------
            // end house type div listeners
            // --------------------------
            
            
        }
}
</script>