<template>
    <div class="flex w-full space-x-8 mt-2 lg:mt-4">
        <div class="relative flex inline border rounded-lg border-black border-opacity-25 w-1/2 h-5 lg:h-full">
            <input 
                :id="minID"
                name="minPrice"
                ref="minPrice" 
                placeholder="Min" 
                type="text" 
                class="font-euclid ml-2 h-4 lg:h-10 text-base placeholder-gray-500 text-black text-opacity-75 w-full" 
                :v-model="min != 0 ? getMin : null"
                @change="onChange"
                :value="getMin == 0 ? '' : getMin">
                <span class="mt-1 lg:mt-2 px-1 text-base text-black justify-center items-center font-euclid rounded-xl whitespace-nowrap align-middle table-cell text-opacity-50">{{ symbol }}</span>
        </div>

        <div class="relative flex inline border rounded-lg border-black border-opacity-25 w-1/2 h-5 lg:h-full">
            <input 
                :id="maxID"
                name="maxPrice"
                ref="maxPrice" 
                placeholder="Max" 
                type="text" 
                class="font-euclid ml-2 h-4 lg:h-10 text-base placeholder-gray-500 text-black text-opacity-75 w-full" 
                :v-model="max != 1000000000000000 ? getMax : null"
                @change="onChange"
                :value="getMax == 1000000000000000 ? '' : getMax">
            <span class="mt-1 lg:mt-2 px-1 text-base text-black justify-center items-center font-euclid rounded-xl whitespace-nowrap align-middle table-cell text-opacity-50">{{ symbol }}</span>
        </div>
        
    </div>
</template>
<style scoped>
.suffix input {
  border-radius: 4px 0px 0px 4px;
}
.suffix .input-group-addon {
  border-left: 0;
  border-radius: 0px 4px 4px 0px;
}
</style>
<script>

export default {
    name: "MinMaxInput",
    props:{
        blockType: String,
        min:{
            Number,
            default: null
        },
        max:{
            Number,
            default: null
        },
        symbol:{
            String,
            default: '€'
        }
    },
    data(){
        return{
            maxID: Math.floor(Math.random() * 999999),
            minID: Math.floor(Math.random() * 999999)
        }
    },
    computed:{
        getMin(){
            return this.min
        },
        getMax(){
            return this.max
        }
    },
    methods: {
        onChange(e){
            let dataMessage = {
                local: "",
                value: e.target.value
            }
            if(e.target.id == this.minID){
                dataMessage.local = "min"
            }else{
                dataMessage.local = "max"
            }
            this.$emit('on-change', this.blockType, dataMessage)
        }
    },
}
</script>