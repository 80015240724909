import { mapActions } from 'vuex'

const mixin = {

  created: function() {
    console.debug("Key model action called")
  },

  methods: {
    ...mapActions(['getKeyItems']),
  }
}

export default mixin