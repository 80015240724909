<template>
    <div class="w-full hidden sm:flex justify-between items-center text-base">
        <!-- numer of results -->
        <p>{{results}} {{ $t('listingsPage.infoBar.results') }}</p>

        <!-- order by -->
        <div class="relative flex items-center justify-end order-section">
            <span class="order-title">{{ $t('listingsPage.order.title') }}</span>
            <select name="order-options"  :value="'most_recent'" @change="onChange($event)" class="pr-4 py-2 block h-full bg-transparent appearance-none cursor-pointer">
                <option :value="i" :key="i" v-for="(item, i) in $t('listingsPage.order.items')">
                  {{item}}
                </option>
            </select>
            <inline-svg fill="black" class="w-3 h-3 transform rotate-90"
                src="/svg/arrow/arrow.svg" />
        </div>
    </div>
</template>
<style scoped>
.order-title{
  display: inline-block;
  padding: 0 .5em;
  width: auto;
}
.order-section select{
  width: auto;
  padding-right: .5em;
}
.order-section{
  width: calc( 100% - 150px);
}
</style>
<script>
export default {
    name: "ListingsInfoBar",
    props: {results: Number},
    methods: {
      onChange(e){
        this.$emit('orderby', e.target.value)
      }
    }
}
</script>