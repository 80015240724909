<template>
    <div :class="getDivStyling">
        <inline-svg fill="black" class="hidden md:block w-3 h-3 absolute top-0 right-0 m-3 transform rotate-90" src="/svg/arrow/arrow.svg" />
        <div class="w-full flex justify-center lg:justify-start lg:ml-4">
            <p class="text-base lg:text-base" :class="getPlaceholderClass">{{ computePlaceholder }}</p>
        </div>
    </div>
</template>
<script>
export default {
    name: "FixedDropdown",
    props:{
        myPlaceholder: String,
        disablePlaceholder:{
            type: Boolean,
            default: false
        },
        // If 1, there is a border. 0 no border
        borders:{
            type: Array,
            default: null // (TOP, BOTTOM, LEFT, RIGHT)
        },
        leftNeighbor: {
            type: Number,
            default: 0
        },
        rightNeighbor: {
            type: Number,
            default: 0
        }
    },
    computed:{
        getDivStyling(){
            let finalClass = 'flex bg-white w-5/6 lg:w-full relative items-center h-5 lg:h-8 relative border-gray lg:border-black lg:border-gray border'

            if(!this.disablePlaceholder)
                finalClass = 'flex bg-white w-5/6 lg:w-full relative items-center h-5 lg:h-8 relative border-bronze lg:border-bronze lg:border-bronze border'

            return finalClass
        },
        getPlaceholderClass(){
            let finalClass = ''
            if(this.disablePlaceholder){
                finalClass += 'text-opacity-25 text-black'
                this.$emit('disable-me')
            }else this.$emit('activate-me')
            
            return finalClass
        },
        computePlaceholder(){
              return this.myPlaceholder
          }
    },
    methods:{
        updatePlaceholder(pl){
            this.myPlaceholder = pl
        }
    }
}
</script>