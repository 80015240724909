<template>
    <div v-if="dataLoading" class="w-full listingPageContainer">
        <FiltersMain
          :key="filtersMainKey"
          @on-change="onChangeFilterMain" 
          @open-price-div="openPricingDiv" 
          @open-house-type-div="openHouseTypeDiv"
          @open-bedroom-div="openBedroomDiv" 
          @open-filters="openFilters"
          @input-location-changed="inputLocationChanged"
          @remove-filters="resetFilters"
          :houseTypes="houseTypes" 
          :beds="beds" 
          :saleRentingDrop="saleRentingDrop" 
          :filters="filters" 
          class="lg:fixed lg:top-0"
          :filtersQty="computeFiltersQty"
          ref="filtersMainRef"
          :cityVisiting="computeCityVisiting"
        />
        <transition name="slide">
            <AdvancedFiltersBar 
                :key="advancedFilterKey"
                @on-change="onChange" 
                :filters="filters" 
                :houseTypes="houseTypes" 
                :floor="floor" 
                :parking="parking" 
                :wcs="wcs" 
                :beds="beds" 
                :saleRentingDrop="saleRentingDrop" 
                class="duration-700" 
                @open-filters="openFilters" 
                v-if="show" 
                :totalResults="computeTotalResults"
                :filtersQty="computeFiltersQty"
                @remove-filters="resetFilters"
                @uncheck-segment="uncheckSegment"
                />
        </transition>

        <MapView :key="mapViewKey" v-if='this.returnViewMap' :searchedPlace="searchedPlace" :filters="filters" :properties_items="items" :place="googleMapPlace" @filter-properties-list="filterPropertiesList"/>
        <div v-else>
            <ListingsView class="block" @total-results="totalResultsUpdate"
                :properties_items="items" 
                :place="googleMapPlace"
                :key="listingViewKey"
                :condominiums_items="itemsCondominiums"
                :ifEmptyList="generateEmptyList"
                :filters="filters"/>
            <!-- <MobileListingsView class="sm:hidden block" @total-results="totalResultsUpdate" :properties_items="items" :filters="filters" /> -->
        </div>
        
        
        <!-- <transition name="slide"> -->
            <PriceDropdownMenu 
                :style="pricingDivPosition" 
                :filters="filters" 
                @clean-price-filter="cleanPriceFilter" 
                @on-change="onChangeFilterMain"
                @close-me="pricingDivShow=false" 
                v-if='pricingDivShow'/>

            <BedroomsDropdownMenu 
                :style="bedroomsDivPosition" 
                :filters="beds" 
                :filtersInUse="filters"
                blockType="beds" 
                @on-change="onChangeFilterMain" 
                @close-me="bedroomDivShow=false" 
                v-if='bedroomDivShow'
                @clean-price-filter="cleanBedsFilter"
            />
            
            <HouseTypeDropdownMenu 
                :style="houseTypeDivPosition" 
                :filters="houseTypes" 
                blockType="houseType" 
                @on-change="onChangeFilterMain" 
                @close-me="houseTypeDivShow=false" 
                v-if='houseTypeDivShow'
                @clean-price-filter="cleanHouseFilter"
            />
        <!-- </transition> -->
        <TheFooter 
            :items="listingsFooter"
            @open-privacy-window="openPrivacyWindow"
            @city-changed="cityChanged"
            @segment-changed="activateSegmentFilter"
            @lifestyle-changed="activateSegmentFilter"
        />
    </div>
</template>
<style scoped>
.listingPageContainer{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
<script>
import FiltersMain from "@/components/listings/filters/bar/FiltersMain.vue";
import AdvancedFiltersBar from "@/components/listings/filters/advancedFilters/AdvancedFiltersBar.vue";
import MapView from "@/components/listings/MapView.vue";
import ListingsView from "@/components/listings/ListingsView.vue";
// import MobileListingsView from "@/components/listings/mobile/MobileListingsView.vue";
import TheFooter from '@/layout/footer/FooterExtended'
import PriceDropdownMenu from "@/components/listings/filters/bar/PriceDropdownMenu.vue";
import BedroomsDropdownMenu from "@/components/listings/filters/bar/BedroomsDropdownMenu.vue";
import HouseTypeDropdownMenu from "@/components/listings/filters/bar/HouseTypeDropdownMenu.vue";

import {
    store,
} from '@/core/store'

import { Property, PropertyHomePage } from '@/store/structs/properties'
import mixin from '@/models/properties/PropertiesModel'

import { Condominium } from '@/store/structs/condominium'
import mixinCondominium from '@/models/condominium/CondominiumsModel'

let saleRentingDrop = [
  {  
    name: 
        {
            pt: "Comprar",
            en: "For Sale"
        }
  },
  {
    name: 
        {
            pt: "Arrendar",
            en: "For Rent"
        }
  }
]

let beds = [
  {  
    name: 
        {
            pt: "0",
            en: "0"
        }
  },
  {
    name: 
        {
            pt: "1",
            en: "1"
        }
  },
  {  
    name: 
        {
            pt: "2",
            en: "2"
        }
  },
  {
    name: 
        {
            pt: "3",
            en: "3"
        }
  },
  {  
    name: 
        {
            pt: "4",
            en: "4"
        }
  },
  {  
    name: 
        {
            pt: "5+",
            en: "5+"
        }
  }
]

let wcs = [
  {  
    name: 
        {
            pt: "0+",
            en: "0+"
        }
  },
  {
    name: 
        {
            pt: "1+",
            en: "1+"
        }
  },
  {  
    name: 
        {
            pt: "2+",
            en: "2+"
        }
  },
  {
    name: 
        {
            pt: "3+",
            en: "3+"
        }
  },
  {  
    name: 
        {
            pt: "4+",
            en: "4+"
        }
  },
  {  
    name: 
        {
            pt: "5+",
            en: "5+"
        }
  }
]

let parking = [
  {  
    name: 
        {
            pt: "0+",
            en: "0+"
        }
  },
  {
    name: 
        {
            pt: "1+",
            en: "1+"
        }
  },
  {  
    name: 
        {
            pt: "2+",
            en: "2+"
        }
  },
  {
    name: 
        {
            pt: "3+",
            en: "3+"
        }
  },
  {  
    name: 
        {
            pt: "4+",
            en: "4+"
        }
  },
  {  
    name: 
        {
            pt: "5+",
            en: "5+"
        }
  }
]

let floor = [
  {  
    name: 
        {
            pt: "0+",
            en: "0+"
        }
  },
  {
    name: 
        {
            pt: "1+",
            en: "1+"
        }
  },
  {  
    name: 
        {
            pt: "2+",
            en: "2+"
        }
  },
  {
    name: 
        {
            pt: "3+",
            en: "3+"
        }
  },
  {  
    name: 
        {
            pt: "4+",
            en: "4+"
        }
  },
  {  
    name: 
        {
            pt: "5+",
            en: "5+"
        }
  }
]

let min_value = 0
let max_value = 1000000000000000

export default {
    name: "ListingsPage",
    components: {
        FiltersMain,
        AdvancedFiltersBar,
        MapView,
        ListingsView,
        TheFooter,
        PriceDropdownMenu,
        BedroomsDropdownMenu,
        HouseTypeDropdownMenu,
        // MobileListingsView,
    },
    mixins: [mixin, mixinCondominium],
    data: () => ({
        mapViewKey: 999,
        listingViewKey: 9999,
        advancedFilterKey: 0,
        propertiesList: null,
        googleMapPlace: null,
        searchedPlace: false,
        emptyList: null,
        filtersQty: 0,
        filtersMainKey: 0,
        cityVisiting: '',
        cityFilterActive: false,
        lifestyleFilterActive: false,
        segmentFilterActive: false,
        gMapFilterActive: false,
        totalResults: 0,
        dataLoading: true,
        show: false,
        pricingDivShow: false,
        bedroomDivShow: false,
        houseTypeDivShow: false,
        resetedFilters: [
            { id: "saleRenting", data: "For sale", label: "For sale", counted: false},
            { id: "houseType", data: "All", label: "All", counted: false },
            { id: "pricing", data: {min: min_value, max: max_value}, label: "-", counted: false },
            { id: "beds", data: "All", label: "All", counted: false},
            { id: "gross", data: {min: min_value, max: max_value}, label: "", counted: false },
            { id: "wcs", data: "0+", label: "0+", counted: false},
            { id: "parking", data: "0+", label: "0+", counted: false},
            { id: "floor", data: "0+", label: "0+", counted: false},
            { id: "energy", data: [0, 7], label: "", counted: false},
            { id: "collection", data: false, label: "", counted: false},
            { id: "elevator", data: true, label: "", counted: false},
            { id: "others", data: [], label: "", counted: false},
            { id: "condominiums", data: false, label: "", counted: false},
            { id: "reference", data: '', label: "", counted: false}
        ],
        filters: [
            { id: "saleRenting", data: "For sale", label: "For sale", counted: false},
            { id: "houseType", data: "All", label: "All", counted: false },
            { id: "pricing", data: {min: min_value, max: max_value}, label: "-", counted: false },
            { id: "beds", data: "All", label: "All", counted: false},
            { id: "gross", data: {min: min_value, max: max_value}, label: "", counted: false },
            { id: "wcs", data: "0+", label: "0+", counted: false},
            { id: "parking", data: "0+", label: "0+", counted: false},
            { id: "floor", data: "0+", label: "0+", counted: false},
            { id: "energy", data: [0, 7], label: "", counted: false},
            { id: "collection", data: false, label: "", counted: false},
            { id: "elevator", data: true, label: "", counted: false},
            { id: "others", data: [], label: "", counted: false},
            { id: "condominiums", data: false, label: "", counted: false},
            { id: "reference", data: '', label: "", counted: false}
        ],
        key_reload: 0,
        filtersMainReload: -1,
        pricingDivPosition:{
            top: 0,
            left: 0,
            right: 0, 
            bottom: 0
        },
        houseTypeDivPosition:{
            top: 0,
            left: 0,
            right: 0, 
            bottom: 0
        },
        bedroomsDivPosition:{
            top: 0,
            left: 0,
            right: 0, 
            bottom: 0
        }
    }),
    async created(){
        try{
            this.dataLoading = false
            this.resetedFilters[0].data = this.$t('listingsPage.for_sale_rent.for_sale')
            this.resetedFilters[0].label = this.$t('listingsPage.for_sale_rent.for_sale')
            this.filters[0].data = this.$t('listingsPage.for_sale_rent.for_sale')
            this.filters[0].label = this.$t('listingsPage.for_sale_rent.for_sale')   
            store.mapView = false
            await this.getProperties()
            await this.getCondominiums()
            this.dataLoading = true


            if(localStorage.getItem("newDevelopments") == "true"){
                localStorage.setItem("newDevelopments", null);
                this.filters.find(p => p.id === "condominiums").data = true
            }
            
            if(this.$route.params.data == 'placeListing'){
                if(sessionStorage.getItem('placeListing')){
                    this.inputLocationChanged(JSON.parse(sessionStorage.getItem('placeListing')));
                    sessionStorage.removeItem('placeListing');
                    this.filtersQty++
                }
            }
            else if(this.$route.params.data != "0"){
                let linkData = this.$route.params.data
                if(linkData.includes('city:')){
                    linkData = linkData.split('city:')[1]
                    
                    if(linkData == 'Lisbon')
                        linkData = 'Lisboa'

                    
                    
                    this.cityVisiting = linkData

                    let components = []
                    components.push({long_name: linkData, short_name: linkData, types: ['locality', 'political']})
                    components.push({long_name: linkData, short_name: linkData, types: ['administrative_area_level_1', 'political']})
                    components.push({long_name: linkData, short_name: linkData, types: ['country', 'political']})

                    this.googleMapPlace = {
                        address_components: components
                    }
                    
                    this.filtersQty++
                    this.cityFilterActive = true
                }
                if(linkData.includes('segment:')){
                    this.filtersQty++
                    this.segmentFilterActive = true
                }
                if(linkData.includes('lifestyle:')){
                    this.filtersQty++
                    this.lifestyleFilterActive = true
                }
            }
            const retrievedData = this.$store.state.propertiesModule.properties

            this.emptyList = Object.keys(retrievedData).map(k =>
                new Property(retrievedData[k])
            )

        }catch(error){
            console.log(error)
        }
        this.$emit('show-header')
    },
    methods: {
        setEmptyList(l){
            this.emptyList = l
        },
        uncheckSegment(){
            this.$router.push({ name: 'listings', params: { lang: this.$i18n.locale, data: '0'}})
            this.refreshAdvancedFilters()
        },
        cityChanged(c){
            this.cityVisiting = c

            let linkData = c
            if(linkData != ''){
                if(linkData == 'Lisbon')
                    linkData = 'Lisboa'

                let components = []
                components.push({long_name: linkData, short_name: linkData, types: ['locality', 'political']})
                components.push({long_name: linkData, short_name: linkData, types: ['administrative_area_level_1', 'political']})
                components.push({long_name: linkData, short_name: linkData, types: ['country', 'political']})
                
                this.googleMapPlace = {
                    address_components: components
                }
                if(!this.cityFilterActive){
                    this.filtersQty++
                    this.cityFilterActive = true
                }
            }
        },
        openPrivacyWindow(){
            let routeData = this.$router.resolve({name: 'privacy', params: { lang: this.$i18n.locale }});
            window.open(routeData.href, '_blank');
        },
        closePrivacyModal(){
            this.privacyModal = false
        },
        resetFilters(){
            this.filters = [
                { id: "saleRenting", data: "For sale", label: "For sale", counted: false},
                { id: "houseType", data: "All", label: "All", counted: false },
                { id: "pricing", data: {min: min_value, max: max_value}, label: "-", counted: false },
                { id: "beds", data: "All", label: "All", counted: false},
                { id: "gross", data: {min: min_value, max: max_value}, label: "", counted: false },
                { id: "wcs", data: "0+", label: "0+", counted: false},
                { id: "parking", data: "0+", label: "0+", counted: false},
                { id: "floor", data: "0+", label: "0+", counted: false},
                { id: "energy", data: [0, 7], label: "", counted: false},
                { id: "collection", data: false, label: "", counted: false},
                { id: "elevator", data: true, label: "", counted: false},
                { id: "others", data: [], label: "", counted: false},
                { id: "condominiums", data: false, label: "", counted: false},                                
                { id: "reference", data: '', label: "", counted: false}
                            ]
            this.setLocalStorageData('filters', JSON.stringify(this.filters))
            this.cleanPriceFilter()
            this.pricingDivShow = !this.pricingDivShow

            this.cleanHouseFilter()            
            this.houseTypeDivShow = !this.houseTypeDivShow

            this.cleanBedsFilter()            
            this.bedroomDivShow = !this.bedroomDivShow

            this.cleanHouseTypeFilter()
            this.filtersMainKey++

            this.filtersQty = 0

            this.googleMapPlace = null
            this.cityVisiting = ''

            this.gMapFilterActive = false

            this.cityFilterActive = false
            this.lifestyleFilterActive = false
            this.segmentFilterActive = false

            let linkData = this.$route.params.data

            if(linkData.includes('city:'))
                window.history.replaceState({}, "", "0");

            if(linkData.includes('lifestyle:'))
                window.history.replaceState({}, "", "0");

                this.$refs.filtersMainRef.resetField()

            this.advancedFilterKey++
            
        },
        filterPropertiesList(list){
            this.propertiesList = list
        },
        inputLocationChanged(place){
            this.googleMapPlace = place    
            this.searchedPlace = true

            if(this.gMapFilterActive == false){
                this.filtersQty++
                this.gMapFilterActive = true
            }
            this.mapViewKey++
            this.listingViewKey++
        },
        cleanPriceFilter(){
            if(this.filters.find(p => p.id === "pricing").counted){
                this.filters.find(p => p.id === "pricing").counted = false
                this.filtersQty--
            }
            this.filters.find(p => p.id === "pricing").data.min = min_value
            this.filters.find(p => p.id === "pricing").data.max = max_value
            this.filters.find(p => p.id === "pricing").label = '-'
            this.pricingDivShow = !this.pricingDivShow
        },
        cleanHouseFilter(){
            if(this.filters.find(p => p.id === "houseType").counted){
                this.filtersQty--
            }
            this.filters.find(p => p.id === "houseType").counted = false
            this.filters.find(p => p.id === "houseType").label = 'All'
            this.filters.find(p => p.id === "houseType").data = 'All'
            this.houseTypeDivShow = !this.houseTypeDivShow
        },
        cleanBedsFilter(){
            if(this.filters.find(p => p.id === "beds").counted){
                this.filtersQty--
            }
            this.filters.find(p => p.id === "beds").counted = false
            this.filters.find(p => p.id === "beds").data = 'All'
            this.filters.find(p => p.id === "beds").label = 'All'
            this.bedroomDivShow = !this.bedroomDivShow
        },
        cleanHouseTypeFilter(){
            this.filters.find(p => p.id === "saleRenting").counted = false
            this.filters.find(p => p.id === "saleRenting").data = 'For sale'
            this.filters.find(p => p.id === "saleRenting").label = 'For sale'
        },
        openFilters() {
            this.show = !this.show;
            if(document.getElementById('mobile-menu-bottom') && this.show) document.getElementById('mobile-menu-bottom').classList?.add('hidden')
            else if(document.getElementById('mobile-menu-bottom') && !this.show) document.getElementById('mobile-menu-bottom').classList?.remove('hidden')
        },
        openPricingDiv(e, bounds){
            this.pricingDivPosition.top = (bounds.top+50) + "px"
            this.pricingDivPosition.left = (bounds.left) + "px"
            this.pricingDivPosition.right = (bounds.right) + "px"
            this.pricingDivPosition.bottom = (bounds.bottom) + "px"
            window.scrollTo(0,0)
            this.bedroomDivShow = false
            this.houseTypeDivShow = false
            this.pricingDivShow = !this.pricingDivShow
        },
        openHouseTypeDiv(e, bounds){
            this.houseTypeDivPosition.top = (bounds.top+50) + "px"
            this.houseTypeDivPosition.left = (bounds.left) + "px"
            this.houseTypeDivPosition.right = (bounds.right) + "px"
            this.houseTypeDivPosition.bottom = (bounds.bottom) + "px"
            window.scrollTo(0,0)
            this.pricingDivShow = false
            this.bedroomDivShow = false
            this.houseTypeDivShow = !this.pricingDivShow
        },
        openBedroomDiv(e, bounds){
            this.bedroomsDivPosition.top = (bounds.top+50) + "px"
            this.bedroomsDivPosition.left = (bounds.left) + "px"
            this.bedroomsDivPosition.right = (bounds.right) + "px"
            this.bedroomsDivPosition.bottom = (bounds.bottom) + "px"
            window.scrollTo(0,0)
            this.pricingDivShow = false
            this.houseTypeDivShow = false
            this.bedroomDivShow = !this.bedroomDivShow
        },
        filtersStringCompose(block_type, data, symbol){
            if(data.local == "min"){                        
                if(this.filters.find(p => p.id === block_type).data.max == 1000000000000000)
                    this.filters.find(p => p.id === block_type).label = "Min: " + data.value + symbol;
                else
                    this.filters.find(p => p.id === block_type).label = data.value + "€ - " + this.filters.find(p => p.id === block_type).data.max + "€"

                    this.filters.find(p => p.id === block_type).data.min = data.value
            }else{
                 if(this.filters.find(p => p.id === block_type).data.min == 0)
                    this.filters.find(p => p.id === block_type).label = "Max: " + data.value + symbol;
                else
                    this.filters.find(p => p.id === block_type).label = this.filters.find(p => p.id === block_type).data.min + "€ - " + data.value + "€"

                this.filters.find(p => p.id === block_type).data.max = data.value    
            }
        },
        filterOthers(block_type, data){
            if(data.target.checked){
                this.filters.find(p => p.id === block_type).data.push(data.target.value)
            }else{
                let newFiltersList = []
                this.filters.find(p => p.id === block_type).data.forEach(function(key){    
                    if(key != data.target.value)
                        newFiltersList.push(key)
                })
                this.filters.find(p => p.id === block_type).data = newFiltersList
            }            
        },
        addFilterQuantity(){
            this.filtersQty += 1
        },
        removeFilterQuantity(){
            this.filtersQty -= 1
        },
        filtersChangeListener(block_type){
            switch(block_type){
                case "saleRenting":
                    if(this.filters.find(p => p.id === block_type).data != "For sale"){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "houseType":
                    if(this.filters.find(p => p.id === block_type).data != "All"){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "pricing":
                    if(this.filters.find(p => p.id === block_type).data.min != this.min_value && this.filters.find(p => p.id === block_type).data.max != this.max_value){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "beds":
                    if(this.filters.find(p => p.id === block_type).data != "All"){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }         
                    break;
                case "gross":                      
                    if(this.filters.find(p => p.id === block_type).data.min != this.min_value && this.filters.find(p => p.id === block_type).data.max != this.max_value){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "wcs":
                    if(this.filters.find(p => p.id === block_type).data != "0+"){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }               
                    break;
                case "parking":
                    if(this.filters.find(p => p.id === block_type).data != "0+"){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "floor":
                    if(this.filters.find(p => p.id === block_type).data != "0+"){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "collection":                    
                    if(this.filters.find(p => p.id === block_type).data != true){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.removeFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                            this.addFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "condominiums":                    
                    if(this.filters.find(p => p.id === block_type).data != true){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.removeFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.addFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "elevator":                    
                    if(this.filters.find(p => p.id === block_type).data != ""){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "energy":
                    if(this.filters.find(p => p.id === block_type).data != ""){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case 'reference':
                    if(this.filters.find(p => p.id === block_type).data != ""){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                case "others":                    
                    if(this.filters.find(p => p.id === block_type).data != ""){
                        if(!this.filters.find(p => p.id === block_type).counted){
                            this.addFilterQuantity()
                            this.filters.find(p => p.id === block_type).counted = true
                        }
                    }else{
                        this.removeFilterQuantity()
                        this.filters.find(p => p.id === block_type).counted = false
                    }
                    break;
                default: {break}
            }
        },
        onChangeFilterMain(block_type, data){
            switch(block_type){
                case "saleRenting":
                    this.filters.find(p => p.id === block_type).data = data
                    // Check filter counter
                    if(!this.filters.find(p => p.id === block_type).counted){
                        this.filters.find(p => p.id === block_type).counted = true
                        this.filtersQty++
                    }else{
                        this.filtersQty--
                        this.filters.find(p => p.id === block_type).counted = false
                    }

                    this.advancedFilterKey++
                    break;
                case "houseType":
                    this.filters.find(p => p.id === block_type).data = data
                    // Check filter counter
                    if(!this.filters.find(p => p.id === block_type).counted){
                        this.filters.find(p => p.id === block_type).counted = true
                        this.filtersQty++
                    }

                    this.advancedFilterKey++
                    break;
                case "pricing":
                    this.filtersStringCompose(block_type, data, '€')
                    this.filters.find(p => p.id === block_type).id = "pricing"
                    // Check filter counter
                    if(!this.filters.find(p => p.id === block_type).counted){
                        this.filters.find(p => p.id === block_type).counted = true
                        this.filtersQty++
                    }
                    this.advancedFilterKey++
                    break;
                case "beds":
                    this.filters.find(p => p.id === block_type).data = data
                    this.filters.find(p => p.id === block_type).label = data
                    // Check filter counter
                    if(!this.filters.find(p => p.id === block_type).counted){
                        this.filters.find(p => p.id === block_type).counted = true
                        this.filtersQty++
                    }
                    this.advancedFilterKey++
                    break;
                case "gross":
                    this.filtersStringCompose(block_type, data, 'm2')         
                    break;
                case "wcs":
                    this.filters.find(p => p.id === block_type).data = data
                    break;
                case "parking":
                    this.filters.find(p => p.id === block_type).data = data
                    break;
                case "floor":
                    this.filters.find(p => p.id === block_type).data = data
                    break;
                case "collection":                    
                    this.filters.find(p => p.id === block_type).data = data.target.checked
                    break;
                case "condominiums":                    
                    this.filters.find(p => p.id === block_type).data = data.target.checked
                    break;
                case "elevator":                    
                    this.filters.find(p => p.id === block_type).data = data.target.checked
                    break;
                case "energy":
                     this.filters.find(p => p.id === block_type).data = data
                    break;
                case "others":                    
                    this.filterOthers(block_type, data)
                    break;
                case 'reference':
                     this.filters.find(p => p.id === block_type).data = data
                    break;
                default:{break}
            }
        },
        onChange(block_type, data){
            switch(block_type){
                case "saleRenting":
                    this.filters.find(p => p.id === block_type).data = data
                    break;
                case "houseType":
                    this.filters.find(p => p.id === block_type).data = data
                    break;
                case "pricing":
                    this.filtersStringCompose(block_type, data, '€')
                    this.filters.find(p => p.id === block_type).id = "pricing"
                    break;
                case "beds":
                    this.filters.find(p => p.id === block_type).data = data
                    this.filters.find(p => p.id === block_type).label = data
                    break;
                case "gross":
                    this.filtersStringCompose(block_type, data, 'm2')         
                    break;
                case "wcs":
                    this.filters.find(p => p.id === block_type).data = data
                    break;
                case "parking":
                    this.filters.find(p => p.id === block_type).data = data
                    break;
                case "floor":
                    this.filters.find(p => p.id === block_type).data = data
                    break;
                case "collection":                    
                    this.filters.find(p => p.id === block_type).data = data.target.checked
                    break;
                case "condominiums":                    
                    this.filters.find(p => p.id === block_type).data = data.target.checked
                    break;
                case "elevator":                    
                    this.filters.find(p => p.id === block_type).data = data.target.checked
                    break;
                case "energy":
                     this.filters.find(p => p.id === block_type).data = data
                    break;
                case 'reference':
                     this.filters.find(p => p.id === block_type).data = data
                    break;
                case "others":                    
                    this.filterOthers(block_type, data)
                    break;
                default:{break}
            }
            this.filtersChangeListener(block_type)
            
            this.setLocalStorageData('filters', JSON.stringify(this.filters))
            
            this.actualPage = 1
            this.listings_view_properties_indexer = [0, 6]
            this.key_reload = this.key_reload + 1
            this.filtersMainReload -= 1
        },
        setLocalStorageData(name, value){
            localStorage.setItem(name, value)
        },
        getLocalStorageData(name){
            return localStorage.getItem(name)
        },
        totalResultsUpdate(t){
            this.totalResults = t
        },
        refreshAdvancedFilters(){
            this.advancedFilterKey++
        },
        activateSegmentFilter(){
            if(!this.segmentFilterActive){
                this.filtersQty++
                this.segmentFilterActive = true
            }
        },
        activateLifestyleFilter(){
            if(!this.lifestyleFilterActive){
                this.filtersQty++
                this.lifestyleFilterActive = true
            }
        },
        hackGoogleMapPlace(city, type){
            this.googleMapPlace.address_components.push({'long_name': city, 'short_name': city, 'types':[type, type]})
        }
    },
    computed: {
        generateEmptyList(){
            return this.emptyList
        },
        listingsFooter() {
            let retrievedData = this.$store.state.propertiesModule.properties
           
            retrievedData = retrievedData.sort((a, b) => b.total_views - a.total_views );
            
            let processedData = []
            retrievedData.forEach(element => {
                if(element.collection == 1)
                    processedData.push(element)
            });
            
            return Object.keys(processedData).map(k =>
                new PropertyHomePage(processedData[k])
            )
        },
        returnViewMap() {
            return store.mapView
        },
        items() {
            const retrievedData = this.$store.state.propertiesModule.properties
            
            

            // Search by City
            if(this.$route.params.data != "0"){
                let linkData = this.$route.params.data
                if(linkData.includes('city:')){
                    linkData = linkData.split('city:')[1]

                    // If Lisbon, translate to portuguese to work in the main language
                    if(linkData == 'Lisbon')
                        linkData = 'Lisboa'

                    // Arrays to organize level from cities
                    let areaLeve3Array = []
                    let areaLeve2Array = []
                    let areaCounty = []
                    let areaDistrict = []
                    let algarveProperties = []
                    let routeLevel = []
                    let parent = this

                    retrievedData.forEach(element => {

                        
                        // IF ALGARVE, DO DIFFERENT
                        if(linkData == "Algarve"){
                            if(parent.$isFromAlgarve(element.gmap_district))
                                algarveProperties.push(new Property(element))

                        }else{
                            // LEVEL 1 - THE SMALLEST ROUTE POSSIBLE LIKE THE STREET
                            if(element.route == linkData)
                                routeLevel.push(new Property(element))

                            // LEVEL 2 - THE SECOND SMALLEST ROUTE POSSIBLE
                            if(element.administrative_area_level_3 == linkData)
                                areaLeve3Array.push(new Property(element))

                            // LEVEL 3 - THE THIRD SMALLEST ROUTE POSSIBLE LIKE THE SMALL CITY
                            if(element.administrative_area_level_2 == linkData)
                                areaLeve2Array.push(new Property(element))

                            // LEVEL 4 - THE COUNTY LEVEL
                            if(element.gmap_county == linkData)
                                areaCounty.push(new Property(element))

                            // LEVEL 5 - THE DISTRICT LEVEL
                            if(element.gmap_district == linkData)
                                areaDistrict.push(new Property(element))

                        }
                       
                    });

                    // If linkData brings algarve, then return algarve array
                    if(linkData == "Algarve")
                        return algarveProperties

                    // If we have properties at the smallest level (route level), return them (all other must be empty)
                    if(routeLevel.length != 0)
                        return routeLevel

                    // If we have properties at the second smallest level, return them (all other must be empty)
                    if(areaLeve3Array.length != 0)
                        return areaLeve3Array

                    // If we have properties at the third smallest level, return them (all other must be empty)
                    if(areaLeve2Array.length != 0)
                        return areaLeve2Array
                    
                    // If we have properties at the county level, return them (all other must be empty)
                    if(areaCounty.length != 0)
                        return areaCounty

                    // If we have properties at the district level, return them (all other must be empty)
                    if(areaDistrict.length != 0)
                        return areaDistrict

                }

                // VERIFICAR O SEGMENTO
                if(linkData.includes('segment:')){
                    linkData = linkData.split('segment:')[1]
                    const propertiesReturned = []
                    if(linkData == 'Residential' || linkData == 'Residencial'){
                        retrievedData.forEach(element => {
                            if(element.search.listing_id == 1)
                                propertiesReturned.push(new Property(element))
                        });
                    }
                    if(linkData == 'Comercial' || linkData == 'Commercial'){
                        retrievedData.forEach(element => {
                            if(element.search.listing_id == 2)
                                propertiesReturned.push(new Property(element))
                        });
                    }
                    if(linkData == 'Office' || linkData == 'Escritório'){
                        retrievedData.forEach(element => {
                            if(element.search.listing_id == 3)
                                propertiesReturned.push(new Property(element))
                        });
                    }
                        
                    this.refreshAdvancedFilters()
                    return propertiesReturned
                }

                if(linkData.includes('lifestyle:')){
                    linkData = linkData.split('lifestyle:')[1]
                    
                    const propertiesReturned = []

                    retrievedData.forEach(element => {
                        if(element.search.lifestyles_id.indexOf(parseInt(linkData)+1) > -1){
                            propertiesReturned.push(new Property(element))
                        }

                    });
                    
                    return propertiesReturned
                }
            }
            
            if(this.googleMapPlace != null){

                if(this.googleMapPlace.formatted_address == 'Portugal'){
                    return Object.keys(retrievedData).map(k =>
                        new Property(retrievedData[k])
                    )
                }
                if(this.googleMapPlace.formatted_address == 'Área Metropolitana de Lisboa, Portugal'){
                    this.hackGoogleMapPlace('Lisboa', 'administrative_area_level_2')
                }
                if(this.googleMapPlace.formatted_address == 'Porto Metropolitan Area, Portugal'){
                    this.hackGoogleMapPlace('Porto', 'administrative_area_level_2')
                }
                
                
                // Arrays
                //let areaLeve3Array = []
                //let areaLeve2Array = []
                //let routeLevel = []
                //let areaDistrict = []
                let parent = this

                // new arrays
                let gmap_district = []
                let administrative_area_level_2 = []
                let administrative_area_level_3 = []
                let political = []
                let route = []

                // Procurar já a lista que pertence à cidade
                let cityList = []
                retrievedData.forEach(value => {
                    if(value.route != null){
                        parent.googleMapPlace.address_components.forEach(addr => {
                        // GET DISTRICT LEVEL
                            if(addr.types[0] == "administrative_area_level_1"){
                                if(value.gmap_district != null)
                                    if(value.gmap_district == addr.long_name || value.gmap_district.indexOf(addr.long_name) !== -1)
                                    cityList.push(value)
                            }
                        })
                    }
                })

                console.log("COMPONENTES")
                console.log(parent.googleMapPlace.address_components)
                
                parent.googleMapPlace.address_components.forEach(addr => {
                    
                    for(const address_type of addr.types){
                            console.log("ADRE")
                            console.log(address_type)
                        cityList.forEach(element => {
                            if(address_type == "route"){
                                if(element.route != null)
                                    if(element.route == addr.long_name || element.route.indexOf(addr.long_name) !== -1)
                                        route.push(new Property(element))

                            }

                            if(address_type == "political"){
                                if(element.political == addr.long_name)
                                if(element.political != null)
                                    if(element.political == addr.long_name || element.political.indexOf(addr.long_name) !== -1)
                                        political.push(new Property(element))

                            }

                            if(address_type == "administrative_area_level_3" || address_type == "locality"){
                                if(element.administrative_area_level_3 != null)
                                    if(element.administrative_area_level_3 == addr.long_name || element.administrative_area_level_3.indexOf(addr.long_name) !== -1)
                                        administrative_area_level_3.push(new Property(element))

                            }

                            if(address_type == "administrative_area_level_2"){
                                if(element.administrative_area_level_2 != null)
                                    if(element.administrative_area_level_2 == addr.long_name || element.administrative_area_level_2.indexOf(addr.long_name) !== -1)
                                        administrative_area_level_2.push(new Property(element))

                            }

                            if(address_type == "administrative_area_level_1"){
                                if(element.gmap_district != null)
                                    if(element.gmap_district == addr.long_name || element.gmap_district.indexOf(addr.long_name) !== -1)
                                        gmap_district.push(new Property(element))

                            }

                            /*
                            // GET SMALLEST LEVEL
                            if(address_type == "route" || address_type== "neighborhood"){
                                if(element.route != null)
                                    if(element.route == addr.long_name || element.route.indexOf(addr.long_name) !== -1 || element.administrative_area_level_3 == addr.long_name || element.administrative_area_level_3.indexOf(addr.long_name) !== -1)
                                        if(routeLevel.length <= 0)
                                            routeLevel.push(new Property(element))
                            }
                            
                            // GET SMALLEST LEVEL
                            if(address_type == "administrative_area_level_3" || address_type == "locality" || address_type == "administrative_area_level_2"){
                                if(element.administrative_area_level_3 != null)
                                    if(element.administrative_area_level_2 == addr.long_name || element.administrative_area_level_2.indexOf(addr.long_name) !== -1 || element.administrative_area_level_3 == addr.long_name || element.administrative_area_level_3.indexOf(addr.long_name) !== -1)
                                        if(areaLeve3Array.length <= 0)    
                                            areaLeve3Array.push(new Property(element))
                            }


                            // GET SECOND SMALLEST LEVEL
                            if(address_type == "administrative_area_level_2" || address_type == "locality"){
                                if(element.administrative_area_level_2 != null)
                                    if(element.administrative_area_level_2 == addr.long_name || element.administrative_area_level_2.indexOf(addr.long_name) !== -1)
                                        if(areaLeve2Array.length <= 0)    
                                            areaLeve2Array.push(new Property(element))
                            }

                            // GET DISTRICT LEVEL
                            if(address_type == "administrative_area_level_1"){
                                if(element.gmap_district != null)
                                    if(element.gmap_district == addr.long_name || element.gmap_district.indexOf(addr.long_name) !== -1)
                                        if(areaDistrict.length <= 0)    
                                        areaDistrict.push(new Property(element))    
                            }
                            */
                        

                        })
                    }
                })  

                console.log("COMEÇA")
                console.log(gmap_district)
                console.log(administrative_area_level_2)
                console.log(administrative_area_level_3)
                console.log(political)
                console.log(route)


                let districtReturner2 = false

                if(this.googleMapPlace.address_components[0].types == 'locality')
                    if(this.$isDistrict(this.googleMapPlace.address_components[0].long_name))
                        districtReturner2 = true

                if(districtReturner2)
                    return gmap_district

                if(route.length > 0)
                    return route
                if(political.length > 0)
                    return political
                if(administrative_area_level_3.length > 0)
                    return administrative_area_level_3
                if(administrative_area_level_2.length > 0)
                    return administrative_area_level_2
                if(gmap_district.length > 0)
                    return gmap_district

                return []
                /*

                // Se o que procuramos é um distrito, retorna já o array dos distritos
                let districtReturner = false

                if(this.googleMapPlace.address_components[0].types == 'locality')
                    if(this.$isDistrict(this.googleMapPlace.address_components[0].long_name))
                        districtReturner = true

                        
                this.googleMapPlace.address_components.forEach(addr => {
                    console.log(addr)
                    for(const address_type of addr.types){
                        if(address_type == "locality")
                            if(this.$isDistrict(addr.long_name))
                                districtReturner = true
                    }
                })

                
                if(districtReturner && routeLevel.length == 0)
                    return areaDistrict


                let typeOfChoosenCity = this.googleMapPlace.address_components[0].types[0]
                if(typeOfChoosenCity == 'locality')
                    typeOfChoosenCity = "administrative_area_level_2"

                

                // If we have properties at the smallest level (by route), return them (all other must be empty)
                if(routeLevel.length != 0)
                    return routeLevel
                else{
                    // it means that we are looking for routes. Its empty. Try to send next ones to empty list
                    if(typeOfChoosenCity == 'route' || typeOfChoosenCity == "neighborhood"){
                        if(areaLeve3Array.length != 0){
                            this.setEmptyList(areaLeve3Array)
                            return []
                        }else if(areaLeve2Array.length != 0){
                            this.setEmptyList(areaLeve2Array)
                            return []
                        }else if(areaDistrict.length != 0){
                            this.setEmptyList(areaDistrict)
                            return []
                        }else{
                            this.setEmptyList(this.getAllProperties)
                            return []
                        }
                    }
                }

                // If we have properties at the second smallest level, return them (all other must be empty)
                if(areaLeve3Array.length != 0){
                    return areaLeve3Array
                }
                else{
                    // it means that we are looking for area level 3 routes. Its empty. Try to send next ones to empty list
                    if(typeOfChoosenCity == 'administrative_area_level_3'){
                        if(areaLeve2Array.length != 0){
                            this.setEmptyList(areaLeve2Array)
                            return []
                        }else if(areaDistrict.length != 0){
                            this.setEmptyList(areaDistrict)
                            return []
                        }else{
                            this.setEmptyList(this.getAllProperties)
                            return []
                        }
                    }
                }

                // If we have properties at the third smallest level, return them (all other must be empty)
                if(areaLeve2Array.length != 0)
                    return areaLeve2Array
                else{
                    // it means that we are looking for area level 2 routes. Its empty. Try to send next ones to empty list
                    if(typeOfChoosenCity == 'administrative_area_level_2'){
                        if(areaDistrict.length != 0){
                            this.setEmptyList(areaDistrict)
                            return []
                        }else{
                            this.setEmptyList(this.getAllProperties)
                            return []
                        }
                    }
                    
                }
                
                // If we have properties at the district level, return them (all other must be empty)
                if(areaDistrict.length != 0)
                    return areaDistrict
                    else{
                        // it means that we are looking for area level 2 routes. Its empty. Try to send next ones to empty list
                        if(typeOfChoosenCity == 'administrative_area_level_2'){
                                this.setEmptyList(this.getAllProperties)
                                return []
                        }
                    
                    }

                return []
                */
            }

            // Listing Type

            return Object.keys(retrievedData).map(k =>
                new Property(retrievedData[k])
            )
        },
        getAllProperties(){
            const retrievedData = this.$store.state.propertiesModule.properties
            return Object.keys(retrievedData).map(k =>
                new Property(retrievedData[k])
            )
        },
        itemsCondominiums(){
            let retrievedData = this.$store.state.condominiumsModule.condominiums

            return Object.keys(retrievedData).map(k =>
                new Condominium(retrievedData[k])
            )
        },
        saleRentingDrop(){
            return saleRentingDrop
        },
        beds(){
            return beds
        },
        wcs(){
            return wcs
        },
        parking(){
            return parking
        },
        floor(){
            return floor
        },
        houseTypes(){
            return this.$store.state.keysModule.key.types.data
        },
        computeFiltersQty(){
            return this.filtersQty
        },
        computeTotalResults(){
            return this.totalResults
        },
        computeCityVisiting(){
            return this.cityVisiting
        }
    },
}
</script>