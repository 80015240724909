<template>
     <div class="flex-shrink p-2 items-center justify-center flex relative w-5/6 lg:w-full h-5 lg:h-8 rounded-lg bg-bronze">
            <!-- DESKTOP -->
            <inline-svg class="hidden md:block" fill="white" height="1rem" width="1rem" src="/svg/filter.svg" />
            <!-- END DESKTOP -->

            <!-- MOBILE -->
            <inline-svg class="sm:hidden" fill="white" height="0.65rem" width="0.65rem" src="/svg/filter.svg" />
            <!-- END MOBILE -->

            <p class="text-white ml-2" v-if="advancedFiltersNumber > 0">{{advancedFiltersNumber}}</p>
    </div>
</template>
<script>
export default {
    name: "FiltersButton",
    props: {advancedFiltersNumber: Number}
}
</script>