<template>
    <div :class="getDivStyling">
        <inline-svg fill="black" class="hidden md:block w-3 h-3 absolute top-0 right-0 m-3 transform rotate-90" src="/svg/arrow/arrow.svg" />
        <div class="w-full flex justify-center lg:justify-start lg:ml-4">
            <select 
            @change="onChange($event)" 
            :class="changedOriginal ? placeHolderEffect.changed : placeHolderEffect.active"
        >
            <option disabled hidden selected class="text-base lg:text-base" :class="getPlaceholderClass">{{myPlaceholder}}</option>

            <option :class="placeHolderEffect.disabled"
            >
                {{ $t('listingsPage.for_sale_rent.for_sale')}}
            </option>

            <option :class="placeHolderEffect.disabled"
            >
                {{ $t('listingsPage.for_sale_rent.for_rent')}}
            </option>
        </select>
        </div>
    </div>
</template>
<script>
export default {
    name: "OptionsDropdown",
    props:{
        myPlaceholder: String,
        disablePlaceholder:{
            type: Boolean,
            default: false
        },
        mobile: {
            type: Boolean,
            default: false
        },
    },
    data(){
        if(this.mobile){
            return{
                changedOriginal: false,
                hideOptions: true,
                placeHolderEffect: {
                    active: 'text-center text-opacity-25 font-medium font-euclidblock h-full bg-transparent w-full appearance-none cursor-pointer',
                    disabled: 'text-black text-center font-euclid block h-full bg-transparent w-full appearance-none cursor-pointer',
                    changed: 'text-black text-center font-medium font-euclid block h-full bg-transparent w-full appearance-none cursor-pointer',
                }
            }
        }
        else{
            return{
                changedOriginal: false,
                hideOptions: true,
                placeHolderEffect: {
                    active: 'text-black text-base text-opacity-25 font-medium font-euclid px-4 block h-full bg-transparent w-full appearance-none cursor-pointer',
                    disabled: 'text-black text-base font-euclid px-4 block h-full bg-transparent w-full appearance-none cursor-pointer',
                    changed: 'text-black text-base font-medium font-euclid px-4 block h-full bg-transparent w-full appearance-none cursor-pointer',
                }
            }
        }
    },
    computed:{
        getDivStyling(){
            let finalClass = 'flex bg-white w-5/6 lg:w-full relative items-center h-5 lg:h-8 relative border-gray lg:border-black lg:border-gray border'

            if(!this.disablePlaceholder)
                finalClass = 'flex bg-white w-5/6 lg:w-full text-black relative items-center h-5 lg:h-8 relative border-bronze lg:border-bronze lg:border-bronze border'

            return finalClass
        },
        getPlaceholderClass(){
            let finalClass = ''
            if(this.disablePlaceholder){
                finalClass += 'text-opacity-25 text-black'
                this.$emit('disable-me')
            }else this.$emit('activate-me')
            
            return finalClass
        },
    },
    methods:{
        onChange(e){
            if(e.target.value != this.$t('listingsPage.for_sale_rent.for_sale'))
                this.changedOriginal = true
            else
                this.changedOriginal = false
            this.$emit('on-change', e.target.value)
        }
    }
}
</script>