var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.reloadDiv},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"gmapLocationSearch gmap-principal-div font-bold text-base rounded-lg border border-solid border-bronze"},[_c('h2',{staticClass:"ml-4 mt-4"},[_vm._v("Search location")]),_c('div',{staticClass:"-mt-6 lg:h-8 md:h-10 h-6 border-b-2 border-lightgray flex-none px-2 md:px-8 flex justify-between items-center"}),_c('gmap-autocomplete',{staticClass:"text-baselg font-euclid ml-4 mt-4 font-euclid w-full h-10 bg-transparent text-base placeholder-gray-500 transition-opacity border-opacity-25 focus:shadow-outline focus:border-opacity-100",staticStyle:{"background-color":"white","width":"70%"},attrs:{"options":{
                      componentRestrictions: { country: "pt" },
                      fields: ["address_components", "geometry", "icon", "name"],
                      strictBounds: false,
                      types: ["geocode"],
                  }},on:{"place_changed":_vm.setPlace}})],1),_c('GmapMap',{ref:"map",staticStyle:{"width":"100%","height":"300px","margin-left":"auto","margin-right":"auto","position":"unset"},attrs:{"id":"gMap","center":_vm.center,"zoom":_vm.zoomValue,"map-type-id":"roadmap","options":_vm.getOptions},on:{"zoom_changed":_vm.filterMarkers,"dragend":_vm.filterMarkers}},[_vm._l((_vm.items),function(m,i){return _c('GmapCircle',{key:'Pos: ' + i,attrs:{"center":m.position,"radius":m.radius,"visible":true,"options":_vm.selectedMark==m.id ? 
                    {
                      strokeColor: '#000000',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: '#000000',
                      fillOpacity:0.35
                    } : 
                    { 
                      strokeColor: '#BE5E3B',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      fillColor: '#BE5E3B',
                      fillOpacity:0.35
                    }},on:{"click":function($event){return _vm.showTooltip(m)}}})}),(_vm.InformationWindowTemplateData.showInfoTemplate)?_c('GmapInfoWindow',{attrs:{"options":{maxWidth: 400},"position":_vm.infoWindow.position,"opened":_vm.infoWindow.open},on:{"closeclick":function($event){_vm.infoWindow.open=false}}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeGmapInfoWindow),expression:"closeGmapInfoWindow"}],staticClass:"overflow-hidden flex flex-col"},[_c('div',{staticClass:"relative"},[_c('div',{staticClass:"absolute left-2 bottom-2 md:left-3 md:bottom-3 z-10 flex justify-center items-center rounded-md md:rounded-lg p-0 h-5 md:h-auto px-2 md:py-1 md:px-4 glass-effect glass-fallback"},[_c('p',{staticClass:"text-darkgray text-base font-semibold"},[_vm._v(_vm._s(_vm.InformationWindowTemplateData.selectedMarkerPrice))])]),_c('div',{staticClass:"overflow-hidden listing-card rounded-lg hover:border-bronze border-2 transition-all duration-300 border-transparent lg:rounded-2xl w-full"},[_c('img',{staticClass:"lg:rounded-xl rounded-lg cursor-pointer object-center w-full h-full object-cover hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform",attrs:{"src":this.$mediaFile("properties", _vm.InformationWindowTemplateData.selectedMarkerID , 
                  _vm.InformationWindowTemplateData.selectedMarkerCover),"lazy-src":this.$mediaFile("properties", _vm.InformationWindowTemplateData.selectedMarkerID , 
                  _vm.InformationWindowTemplateData.selectedMarkerCover)},on:{"click":function($event){return _vm.generateLink(_vm.InformationWindowTemplateData.selectedMarkerID)}}})])]),_c('div',{staticClass:"flex space-x-1 md:space-x-2 lg:space-x-3 justify-center"},[_c('PropertyDetailsBig',{attrs:{"homepage":"","number":_vm.InformationWindowTemplateData.bedrooms,"img":"/svg/houseDetails/rooms.svg","color":_vm.color}}),_c('PropertyDetailsBig',{attrs:{"homepage":"","number":_vm.InformationWindowTemplateData.bathrooms,"img":"/svg/houseDetails/baths.svg","color":_vm.color}}),_c('PropertyDetailsBig',{attrs:{"homepage":"","number":_vm.InformationWindowTemplateData.parking,"img":"/svg/houseDetails/garage.svg","color":_vm.color}}),_c('PropertyDetailsBig',{attrs:{"homepage":"","number":_vm.InformationWindowTemplateData.area,"img":"/svg/houseDetails/area.svg","color":_vm.color,"measure":_vm.$t('propertyCard.area')}})],1)])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }