<template>
    <label class="flex justify-between items-center h-4 lg:h-8 px-1 lg:px-2" for="toogleButton">
            <span v-if="label" class="mr-1 lg:mr-2 text-base">{{label}}</span>
            <!-- toggle -->
            <div class="relative">
                <input id="toogleButton" :name="name" v-model="checked" @change="onChange" type="checkbox" class="hidden" />
                <!-- path -->
                <div class="toggle-path bg-gray w-5 lg:w-9 h-2 lg:h-5 rounded-full"></div>
                
                <!-- DESKTOP circle -->
                <div class="hidden md:block toggle-circle absolute w-3.5 h-3.5 bg-white rounded-full inset-y-0 left-0"></div>

                <!-- MOBILE  circle -->
                <div class="sm:hidden toggle-circle-mobile absolute w-1.5 lg:w-3.5 h-1.5 lg:h-3.5 bg-white rounded-full inset-y-0 left-0"></div>
            </div>
    </label>
</template>

<script>
export default {
    name: "switchButton",
    props: {
        name: String,
        label: String,
        defaultChecked: {
            type: Boolean,
        }
    },
    data: function () {
        return {
            checked: this.defaultChecked
        }
    },
    watch:{
        defaultChecked(d){
            this.defaultChecked = d
            this.checked = d
        }
    },

    methods: {
        onChange: function () {
            this.$emit('input', this.checked)
        },
    },
}

</script>