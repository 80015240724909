<template>
    <div class="md:pt-20 w-full mb-8 px-4 md:px-16">
        <!-- THIS WILL APPEAR IF THERE ARE NO RESULTS -->
        <div v-show="!showListingNoResult" class="noresults">
          <h2 class="text-lg noresults-title" >{{$t('listingsPage.no_results.title')}}</h2>
          <p class="noresults-subtitle">{{$t('listingsPage.no_results.subTitle')}}</p>
          <h3 class="text-md noresults-title" >{{$t('listingsPage.no_results.others')}}</h3>
            <div class="grid gap-8 gap-y-8 md:grid-cols-3">
                <a
                    v-for="itemEmptyList in getEmpyList.slice(0, 6)"
                    :key="itemEmptyList.id + 'empty_list'"
                    @click="generateLink(itemEmptyList.id)" 
                >
                    <PropertyCardBig  
                        :baths="new String(itemEmptyList.kpis.baths)"
                        :rooms="new String(itemEmptyList.kpis.beds)"
                        :reference="itemEmptyList.reference"
                        :location="$processLanguage(itemEmptyList.information.location.district)"
                        :cityAddress="getCityAddress(itemEmptyList)"
                        :garage="new String(itemEmptyList.parking)"
                        :area="new String(itemEmptyList.kpis.m2)"
                        :img="$mediaFile('properties', itemEmptyList.id , itemEmptyList.media.cover)"
                        :slogan="$cutText(25, 120, new String($processLanguage(itemEmptyList.information.title))).replace('(...)', '...')"
                        :tag="itemEmptyList.tag" 
                        :price="itemEmptyList.hide_price == 1 ? $t('price_under_request') : $currency(new String(itemEmptyList.price))" 
                        sizeImg="h-img-bigger-card" 
                        color="gray"
                        :dev_id="itemEmptyList.id"
                    />
                </a>
            </div>
        </div>
        <!-- listings-->
        <div class="lg:w-full" v-show="showListingNoResult">
            <ListingsInfoBar :results="computeResultsNumber" @orderby="setOrderby" />
            <div class="grid gap-8 gap-y-8 md:grid-cols-3">
                <a
                   v-for="item in afterList"
                    :key="item.id"
                    @click="generateLink(item.id)" 
                >
                    <PropertyCardBig  
                        :baths="new String(item.kpis.baths)"
                        :rooms="new String(item.kpis.beds)"
                        :reference="item.reference"
                        :location="$processLanguage(item.information.location.district)"
                        :cityAddress="getCityAddress(item)"
                        :garage="new String(item.parking)"
                        :area="new String(item.kpis.m2)"
                        :img="$mediaFile('properties', item.id , item.media.cover)"
                        :slogan="$cutText(45, 60, new String($processLanguage(item.information.title))).replace('(...)', '...')"
                        :tag="item.tag" 
                        :price="item.hide_price == 1 ? $t('price_under_request') : $currency(new String(item.price))" 
                        sizeImg="h-img-bigger-card" 
                        color="gray"
                        :dev_id="item.id"
                    />
                </a>
            </div>
            <!-- begin::navigation -->
                <div class="flex w-100 mt-6 justify-center items-center mt-10 text-bronze">
                    <!-- arrow left -->
                    <div class="relative mr-auto md:mr-2 leading-none">
                        <button @click="firstListingIndexShow">
                            <inline-svg width="1rem" height="1rem" src="/svg/arrow/doubleArrowsLeftGray.svg" viewBox="0 -4 100 100" />
                        </button>
                    </div>
                    <div class="relative mr-auto md:mr-10 leading-none">
                        <button @click="decrementListingIndexShow">
                            <inline-svg class="transform rotate-180" width="1.2rem" height="1.2rem" viewBox="-1 -1 26 26"
                                src="/svg/arrow/arrowBall.svg" />
                        </button>
                    </div>
                    <div class="flex items-center space-x-2 text-lg cursor-pointer">
                        <a @click="goToCurrentPage(0)">
                            <p v-if="currentPage > 1" class="prev-prev-page text-gray text-sm">1</p>
                        </a>

                        <a @click="goToCurrentPage(currentPage - 1)">
                            <p v-if="currentPage > 0" class="prev-page text-gray text-md">{{ currentPage }}</p>
                        </a>
                        <p>{{ currentPage + 1 }}</p>

                        <a @click="goToCurrentPage(currentPage + 1)">
                            <p v-if="(currentPage + 1) < pagesCalculated" class="next-page text-gray text-md">{{ currentPage + 2 }}</p>
                        </a>

                        <a @click="goToCurrentPage(pagesCalculated - 1)">
                            <p v-if="(currentPage + 2) < pagesCalculated" class="next-next-page text-gray text-sm">{{ pagesCalculated }}</p>
                        </a>
                    </div>
                      <!-- arrow right -->
                <div class="relative ml-auto md:ml-10 leading-none">
                    <button @click="incrementListingIndexShow">
                        <inline-svg width="1.2rem" height="1.2rem" src="/svg/arrow/arrowBall.svg" viewBox="-1 -1 26 26" />
                    </button>
                </div>
                <div class="relative ml-auto md:ml-2 leading-none">
                    <button @click="lastListingIndexShow">
                        <inline-svg width="1rem" height="1rem" src="/svg/arrow/doubleArrowsRightGray.svg" viewBox="0 -4 100 100" />
                    </button>
                </div>
            </div>
            <!-- end::navigation -->
        </div>
    </div>
</template>
<style scoped>
.noresults{
  margin-top:3rem;
  margin-bottom: 6rem;
}
.noresults-title{
  font-weight: bold;
  margin-bottom: 1rem;
}
.noresults-subtitle{
  margin-bottom: 5rem;
}
</style>
<script>
    import PropertyCardBig from "@/components/listings/cards/PropertyCardBig";
    import ListingsInfoBar from "@/components/listings/ListingsInfoBar";
    import EnergyEfficiency from '@/data/Energy'

    export default {
        //https://codepen.io/blakewatson/pen/xEXApK
        name: "ListingsView",
        components: {
            PropertyCardBig,
            ListingsInfoBar         
        },
        // ifEmptyList is a list for empty searches. To show a list of other properties that may interest
        props: ['properties_items', 'filters', 'condominiums_items', 'place', 'ifEmptyList'],
        data: () => ({
            allEnergy: EnergyEfficiency,
            listings: null,
            listingsList: 6,
            totalResults: 0,
            pagesCalculated: 0,
            propertiesListInUse: null,
            orderby: 'most_recent',
            currentPage:0
        }),       
        created(){
            this.calculatePages(this.properties_items)
                //console.log("PRO")
                //console.log(this.properties_items)
            this.propertiesListInUse = this.properties_items
            if(screen.width < 768) this.listingsList = 12;
        },
        computed:{
            getEmpyList(){
                return this.ifEmptyList
            },
            showListingNoResult(){
                
                let flag = true
                if(this.filterData.length == 0)
                    flag = false
                    
                
                return flag
            },  
            afterList(){
                const list = this.filterData;
                if(this.orderby == 'most_recent'){
                list.sort((a,b) => {
                    return b.id - a.id
                })
                }
                return list.slice(this.listingsList * this.currentPage, this.listingsList * (this.currentPage + 1))
            },
            filterData: function(){
                // This is the filter function only for properties
                // If the filters is not checked, then we show properties
                // else we show condominiums

                let list = this.applyPropertieFilters()
                
                this.orderby != 'most_recent' && (list = list.sort((p1, p2) => {
                  switch(this.orderby){
                    case 'price_lower_higher': {
                      return p1.price - p2.price;
                    }
                    case 'price_higher_lower': {
                      return p2.price - p1.price 
                    }
                    case 'largest_area': {
                      return p2.kpis.m2 - p1.kpis.m2
                    }
                  }
                }))

                this.setNewList(list)
                this.setTotalResults(list.length)
                this.calculatePages(list);

                
                return list;
            },            
            computeResultsNumber: function(){
                return this.totalResults
            }
        },
        methods:{
            goToCurrentPage(p){
                this.currentPage = p
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            getCityAddress(i){
                if(i.administrative_area_level_2 == '' || i.administrative_area_level_2 == null)
                    return ''
                
                if(i.political != null)
                    return i.political + ', ' + i.administrative_area_level_3

                return i.administrative_area_level_2 + ', ' + i.administrative_area_level_3
            },
            setOrderby(value){
              this.orderby = value;
            },
            setNewList(list){
                this.propertiesListInUse = list
            },
            calculatePages(list){
                let pages = Math.ceil(list.length / this.listingsList)
                this.pagesCalculated = pages
                this.$emit('total-pages-update', this.pagesCalculated)
            },
            setTotalResults(t){
                this.totalResults = t
                this.$emit('total-results', t)
            },
            energyConverter(energy){
                //if(energy == "Em Processo" || energy == "Exempt" || energy == "In Process" || energy == "Isento")
                    //return 8

                return this.allEnergy.indexOf(energy+"")
            },            
            generateLink(id){
                this.$router.push({ name: 'listing', params: { lang: this.$i18n.locale, slug: id }})
            },
            incrementListingIndexShow(){
                if(this.currentPage >= this.pagesCalculated - 1)
                    return
                this.currentPage++;
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            decrementListingIndexShow(){               
                if(this.currentPage == 0)
                    return
                this.currentPage--;
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            firstListingIndexShow(){
                this.currentPage = 0;
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            lastListingIndexShow(){
                this.currentPage = this.pagesCalculated - 1;
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            applyCondominiumFilters(){
                return []
            },
            // Filter properties filter list
            applyPropertieFilters(){
                let list = []
                let filtersList = this.filters
                let parent = this
                
                this.properties_items.forEach(function(key){      
                    let flag = 0
                    
                   
                    
                    // FOR SALE OR RENTING
                    

                    // Se estivermos a procura de vender
                    if(filtersList.find(p => p.id === "saleRenting").data === parent.$t('listingsPage.for_sale_rent.for_sale')){
                        if(key.for_sale == 0)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM


                    // Se estivermos a procura de arrendar
                    if(filtersList.find(p => p.id === "saleRenting").data === parent.$t('listingsPage.for_sale_rent.for_rent')){
                        if(key.for_sale == 1)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    // Look for Area
                    /*if(flag === 0){
                        let linkData = parent.$route.params.data

                        // JUST DO THIS only when the link has no city (it means its not one of the footer calls, so the list is not properly arranged)
                        if(!linkData.includes('city:')){    
                            if(parent.place != null){
                                parent.place.address_components.forEach(addr => {
                                    
                                // LEVEL 1 - THE SMALLEST ROUTE POSSIBLE LIKE THE STREET
                                if(addr.types[0] == "administrative_area_level_1")

                                // LEVEL 2 - THE SECOND SMALLEST ROUTE POSSIBLE LIKE THE SMALL CITY

                                // LEVEL 3 - THE COUNTY LEVEL

                                // LEVEL 4 - THE DISTRICT LEVEL


                                    if(addr.types[0] == "administrative_area_level_1")
                                        if(key.gmap_district == addr.long_name)
                                            flag = 0
                                        else
                                            flag = 1
                                });
                            }else
                                flag = 0
                        }
                    }*/
                    // Fim
                    

                    // COLLECTION
                    
                    if(flag === 0){
                        if(filtersList.find(p => p.id == "collection").data === false)
                            flag = 0
                        else if((key.collection ? 1 : 0) == filtersList.find(p => p.id === "collection").data)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM
                    
                     // PROPERTIES BELONGING TO CONDOMINIUMS
                    if(flag === 0){
                        if(filtersList.find(p => p.id == "condominiums").data === false)
                            flag = 0
                        else if((key.condominium_id != undefined ? 1 : 0) == filtersList.find(p => p.id === "condominiums").data)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    // ELEVATOR
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "elevator").data === true)
                            flag = 0
                        else if(key.elevator === filtersList.find(p => p.id === "elevator").data)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    

                    // TIPO IMOVEL
                    
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "houseType").data === "All")
                            flag = 0
                        else if(parent.$processLanguage(key.type_name) === filtersList.find(p => p.id === "houseType").data)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM
                    

                    // PREÇO
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "pricing").label === "")
                            flag = 0
                        else if(key.price >= filtersList.find(p => p.id === "pricing").data.min && key.price <= filtersList.find(p => p.id === "pricing").data.max)
                            flag = 0
                        else
                            flag = 1
                    }
                    // FIM

                    // Area Bruta                    
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "gross").label === "")
                            flag = 0
                        else if(key.kpis.m2 >= filtersList.find(p => p.id === "gross").data.min && key.kpis.m2 <= filtersList.find(p => p.id === "gross").data.max)
                            flag = 0
                        else
                            flag = 1
                    }

                    // FIM
                    
                    // Quartos
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "beds").data === "----")
                            flag = 0
                        else if(filtersList.find(p => p.id === "beds").data == "All")
                            flag = 0
                        else if(filtersList.find(p => p.id === "beds").data.slice(0, 1) == 5)
                            if(key.kpis.beds >= filtersList.find(p => p.id === "beds").data.slice(0, 1))
                            flag = 0
                                else
                            flag = 1
                        else if(key.kpis.beds == filtersList.find(p => p.id === "beds").data.slice(0, 1))
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim



                    // WCS
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "wcs").data === "----")
                            flag = 0
                        else if(key.kpis.baths >= filtersList.find(p => p.id === "wcs").data.slice(0, 1))
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim
                     

                    let parking_trouble = false
                    if(key.parking == '-'){
                        key.parking = 0
                        parking_trouble = true
                    }
                    if(filtersList.find(p => p.id === "houseType").data != 'Farm'){
                        // ESTACIONAMENTO
                        if(flag === 0){
                            if(filtersList.find(p => p.id === "parking").data === "----")
                                flag = 0
                            else if(key.parking >= filtersList.find(p => p.id === "parking").data.slice(0, 1))
                                flag = 0
                            else
                                flag = 1
                        }
                        // Fim
                    }
                    if(parking_trouble)
                        key.parking = '-'
                    

                    // PISO
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "floor").data === "----")
                            flag = 0
                        else if(key.floor >= filtersList.find(p => p.id === "floor").data.slice(0, 1))
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim
                    


                    // ENERGIA
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "energy").data === "")
                            flag = 0
                        else if(key.energy == "")
                            flag = 0
                        else if(key.energy == null)
                            flag = 0
                        // Check Exceptions || NEED TO CHECK THE FILTER
                        else if(key.energy == "Em Processo" || key.energy == "Exempt" || key.energy == "In Process" || key.energy == "Isento")
                            flag = 0
                        else if(parent.energyConverter(key.energy) >= filtersList.find(p => p.id === "energy").data[0] && parent.energyConverter(key.energy) <= filtersList.find(p => p.id === "energy").data[1])
                            flag = 0
                        else
                            flag = 1
                    }
                    // Fim
                    //console.log(parent.energyConverter(key.energy))

                    //if(energy == "Em Processo" || energy == "Exempt" || energy == "In Process" || energy == "Isento")
                    //return 8

                    // PISO
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "reference").data === '')
                            flag = 0
                        else if(key.reference.includes(filtersList.find(p => p.id === "reference").data)){
                            flag = 0
                        }else flag = 1
                    }
                    // Fim

                     // OTHERS
                    if(flag === 0){
                        if(filtersList.find(p => p.id === "others").data.length === 0)
                            flag = 0
                        else if(key.features_id != ''){
                            let internalFlag = 1
                            let splitFeatures = key.features_id.split(',')
                            filtersList.find(p => p.id === "others").data.forEach(function(key){  
                                if(splitFeatures.indexOf(key) !== -1)
                                    internalFlag = 0
                            })
                            if(internalFlag == 1)
                                flag = 1
                        }
                        else
                            flag = 1
                    }
                    // Fim

                    

                        
                    if(flag === 0)
                        list.push(key)          
                })
                
                return list
            }
        }
    };
</script>